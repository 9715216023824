import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  isBrowser,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";
import { config } from "../../../Config/config";
import environment from "../../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import MessageLightBox from "../../Feature/MessageLightBox";
import HandleSSOtoOtherPlatform from "../../../Services/Common/HandleSSOtoOtherPlatform";
import DashboardStudyGroup from "../../../Services/api/GraphQL/Query/DashboardStudyGroup";
import ResourcesSearchCheckAccessRightGC from "../../../Services/api/GraphQL/Query/ResourcesSearchCheckAccessRightGC";
import VidoeLightBox from "../../Feature/VideoLightBox";
import ImageLightBox from "../../Feature/ImageLightBox";
import GetEPSToken from "../../../Services/api/GraphQL/Query/GetEPSToken";
import MigrateStandaloneAttemptHistory from "../../../Services/api/GraphQL/Mutation/MigrateStandaloneAttemptHistory";
import Loading from "../../Loading/PEC/Loading";
import openIcon from "../../../assets/img/open-in-new-24-px.svg";
import ReadResourceMutation from "../../Feature/Function/ReadResourceMutation";
import useInAppBrowser from "../../../hooks/useInAppBrowser";
import {
  UNIT_PAGE_GET_EPS_TOKEN_QUERY,
  UNIT_PAGE_GET_EPS_TOKEN_STUDENT_QUERY
} from "../../../constants/GraphQLQuery";
import useIosOpenPrizm from "../../../hooks/useIosOpenPrizm";
import useEncryption from "../../../hooks/useEncryption";
import ResourceIconMapping  from "../../../Components/Feature/Function/ResourceIconMappingPEC";
let messageBoxInitState = {
  isOpen: false,
  title: [],
  message: "",
  onConfirm: () => {},
  onCancel: () => {},
  customButton: null,
  customResourceLink:null
};

// let messageBoxInitState = {
//   isOpen: false,
//   title: "",
//   message: "",
//   onConfirm: () => {},
//   onCancel: () => {},
//   customButton: null,
// };

// window.browserRef = null;

const GoogleCodeModal = (props) => {
  // let messageBoxInitState = {
  //   isOpen: props.isOpen,
  //   title: "",
  //   message: props.message,
  //   onConfirm: () => {},
  //   onCancel: () => {
  //     props.setAccessFlag(true);
  //   },
  //   customButton: () => (
  //     <>
  //       <span className="view_notification__popup-border"></span>
  //       <button
  //         className="buttons btns view_notification__popup-save-btn access__right-btn"
  //         onClick={() => {
  //           props.setAccessFlag(true);
  //         }}
  //       >
  //         {props.flag == "access" ? t("Close") : t("Open")}
  //       </button>
  //     </>
  //   ),
  // };
  const { t, accessFlag, setAccessFlag, stopLoader } = props;
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const [accessLoading, setaccessLoading] = useState(false);
  const lang = useSelector((state) => state.SystemReducer.lang);
  // const [haveOpenAction, sethaveOpenAction] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [resourceData, setresourceData] = useState(null);
  const [LightBoxData, setLightBoxData] = useState(null);

  const [noaccess, setnoaccess] = useState(false);
  const [studentStudyGroupId, setstudentStudyGroupId] = useState("");
  const {systemBrowserOpenLink, openLink} = useInAppBrowser();
  const [qrCodeMessageModal, setqrCodeMessageModal] =
    useState(messageBoxInitState);
  const {iosOpenPrizm}  = useIosOpenPrizm();
    const {encrypt, decryptToLocal} = useEncryption();
    const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;
   // let action = true;
  let action = JSON.parse(localStorage.getItem("_action")) || null;
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  useEffect(() => {
//alert("coming")
if (action) {
    if (role == "S") {
      const variables = {
        UserId: userData.UserId,
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        FilterOnboarded: false,
      };
      DashboardStudyGroup(variables, role).then((data) => {
        const {
          user: { BelongsToStudyGroup = [{}] },
        } = data || {};
        let actionData = action.data;
        console.log("testing", BelongsToStudyGroup)
        let defaultGroup = BelongsToStudyGroup.filter((obj) => {
          return (
            (obj.StudyGroup.BookSeries.IngestionId != null ||
              obj.StudyGroup.BookSeries.IngestionId != "" ) && obj.StudyGroup.BookSeries.IsB2CBookSeries != true &&
            obj.StudyGroup.BookSeries.BookSeriesId == actionData.bookseriesId
          );
        });
       
        var studentStudyGroupInfo = defaultGroup[0].StudyGroupId;
        console.log("action", studentStudyGroupInfo)
        setstudentStudyGroupId(studentStudyGroupInfo)
        checkForAccess(studentStudyGroupInfo);
         
      });
    }else {
      checkForAccess();
    }
  }
  }, []);
  // useEffect(() => {
  //   localStorage.removeItem("_action");
  //   stopLoader();
  // }, []);

  
  const checkForAccess = (studentClassId="") => {
    if (action) {
       //alert("qwerty");
      // localStorage.removeItem("_action");
      setaccessLoading(true);
      // stopLoader();
      let actionData = action.data;
      //call query check access right
      let variables = {
        IngestionId: [actionData.gresId],
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        StudyGroupId: role == "S" ? studentClassId : "",
        BookSeriesId:actionData.bookseriesId,
        BookId:actionData.bookId,
        UserId: JSON.parse(localStorage.getItem("userData")).UserId,
      };
      console.log("testing", variables);
      ResourcesSearchCheckAccessRightGC(variables, role).then((data, error) => {
        console.log(data);
        const {
          resourceSearch: { Resources = [{}] },
        } = data || {};
          // var modelDataGC = [];
        const noAccessText = ["You do not have the access right."]
        if (Resources?.length == 0 || Resources == null) {
          setnoaccess(true)
             //if no access
          setaccessLoading(false);
          setqrCodeMessageModal({
            isOpen: true,
            title: noAccessText,
            message: () => {},
            onConfirm: () => {
              setqrCodeMessageModal(messageBoxInitState);
            },
            onCancel: () => {
              setqrCodeMessageModal(messageBoxInitState);
            },
            customButton: () => (
              <>
                <span className="view_notification__popup-border"></span>
                <button
                  className="buttons btns view_notification__popup-save-btn access__right-btn"
                  onClick={() => {
                    setqrCodeMessageModal(messageBoxInitState);
                    localStorage.removeItem("_action");
                  }}
                >
                  {t("Close")}
                </button>
              </>
            ),
          });
        } else {
          const imgPath = ResourceIconMapping(
            Resources[0].ResourceType,
            "big",
            Resources[0].Linkage
          )
          var  modelDataGC = [Resources[0].ResourceLangs[
            lang == Resources[0].ResourceLangs.Lang ? 0 : 1
          ].ResourceName,data.user.AdoptedBookSeries[0].BookSeriesName[0].BookSeriesName,
          data.user.AdoptedBookSeries[0].Books[0].BookName[0].BookName, imgPath, Resources[0].Thumbnail,actionData.chapterNameGC,actionData.unitNameGC];
       
       
          console.log("ResourceData",Resources[0])
          setresourceData(Resources[0]);
          setaccessLoading(false);
          setqrCodeMessageModal({
            isOpen: true,
            title:
            modelDataGC,
            message: "",
            onConfirm: () => {
              setqrCodeMessageModal(messageBoxInitState);
            },
            onCancel: () => {
              setqrCodeMessageModal(messageBoxInitState);
            },

            customResourceLink :()=>{
              return (

                <div class="google_class-resources-title"   
                onClick={() => {
                  qrCodeMessageModal.onCancel();
                  QRCodeOpenResource(
                    Resources[0],
                    actionData.bookseriesId,
                    actionData.bookId,
                    studentClassId
                  );

                }}>
                          <label class="google_class-resources-title-text">
                            {/* Reading Skill PPT */}
                            {modelDataGC[0]}
                          </label>
                        </div>
              )
            },
            customButton: () => {
              return (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    qrCodeMessageModal.onCancel();
                    QRCodeOpenResource(
                      Resources[0],
                      actionData.bookseriesId,
                      actionData.bookId,
                      studentClassId
                    );

                    // localStorage.removeItem("_action");
                  }}
                >
                  {t("Open")}
                </button>
              );
            },
          });
        }
      });
    }

  }

  const handleAppLearnosity = (sitem) => {
    console.log("[Open assessment] App - Inapp browser", sitem);

    setLoading(true);

    var backText =
      localStorage.getItem("i18nextLng") == "en-US" ? "Close" : "關閉";
    var assessmentName = "";
    // sitem.ResourceLangs[
    //   localStorage.getItem("i18nextLng") == sitem.ResourceLangs[0].Lang
    //     ? 0
    //     : 1
    // ].ResourceName.trim();

    var sessionId = localStorage.getItem("SessionId");

    const tokenJson = {
      SessionId: sessionId,
      from: "App",
      Lang: localStorage.getItem("i18nextLng"),
      userData: JSON.stringify(userData),
      userId: JSON.parse(localStorage.getItem("userData")).UserId,
    };

    var tokenJsonToString = JSON.stringify(tokenJson);
    console.log(tokenJson, assessmentName.trim());

    const pageContentUrl =
      config.InAppBrowserOpenAssessmentURL.start +
      "/" +
      sitem.ResourceId +
      (action?.data?.attSession != null ? "/result" : "/start");

    console.log(pageContentUrl, "open");
    window.browserRef = null;
    window.browserRef = window.cordova.InAppBrowser.open(
      pageContentUrl,
      "_blank",
      "closebuttoncaption=" +
        backText +
        ",hidenavigationbuttons=yes,location=no,hideurlbar=yes,clearsessioncache=yes,clearcache=yes,toolbarposition=top,hidden=yes,toolbar=no"
    );
    window.browserRef.addEventListener("exit", function (e) {
      console.log("exit", window.browserRef);
      window.browserRef.close();
      // window.browserRef = null;
    });
    window.browserRef.addEventListener("loadstop", function (e) {
      window.browserRef.executeScript({
        code:
          '\
            localStorage.setItem("from","' +
          tokenJson.from +
          '");\
            localStorage.setItem("SessionId","' +
          tokenJson.SessionId +
          '");\
              localStorage.setItem("i18nextLng-inapp","' +
          tokenJson.Lang +
          '");\
              localStorage.setItem("in-app-browser-data-userid","' +
          tokenJson.userId +
          '");\
          var body = document.querySelector("body");\
          var menuBar = document.createElement("div");\
          var back = document.createElement("div");\
          var title = document.createElement("div");\
          var callBackButton = document.createElement("div");\
          back.innerHTML = "' +
          backText +
          '";\
          title.innerHTML = "' +
          assessmentName +
          '";\
          menuBar.appendChild(back);\
          menuBar.appendChild(title);\
          menuBar.appendChild(callBackButton);\
          callBackButton.id = "app-message-receiver";\
          callBackButton.onclick= function(){\
            localStorage.setItem("close",true);\
            var leaveText = "";\
            if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
            {\
              leaveText = "Are you sure to leave?"\
            }\
            else {\
              leaveText = "確定離開?"\
            }\
            var r = confirm(leaveText);\
            if (r == true) {\
              webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResource",data:localStorage.getItem("callbackdata")}));\
            } \
          };\
          back.onclick= function(){\
              localStorage.setItem("close",true);\
              var leaveText = "";\
              if(localStorage.getItem("i18nextLng-inapp")=="en-US")\
              {\
                leaveText = "Are you sure to leave?"\
              }\
              else {\
                leaveText = "確定離開?"\
              }\
              var r = confirm(leaveText);\
              if (r == true) {\
                  webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));\
              } \
          };\
          menuBar.classList.add("inapp-browser-menu");\
          back.classList.add("inapp-browser-menu-back");\
          title.classList.add("inapp-browser-menu-title");\
          body.appendChild(menuBar);\
          ',
      });

      window.browserRef.insertCSS({
        code: "\
                .inapp-browser-menu {\
                    position: fixed;\
                    top: 0;\
                    right: 0;\
                    left: 0;\
                    z-index: 10001;\
                    padding: 10px;\
                    height: 45px;\
                    background-color: #fff;\
                    border-bottom: 1px solid #dddddd;\
                    display: flex;\
                    justify-content: center;}\
                .inapp-browser-menu-back {\
                    position: absolute;\
                    left: 25px;}\
                .inapp-browser-menu-title{\
                }\
            ",
      });
      window.browserRef.show();
      setLoading(false);
    });

    window.browserRef.addEventListener("message", function (params) {
      if (params.data.action === "closeIAB") {
        window.browserRef.close();
        //console
        // window.browserRef = null;
      }
      if (params.data.action === "openResource") {
        window.browserRef.close();
        // window.browserRef = null;
        setTimeout(() => {
          QRCodeOpenResource(JSON.parse(params.data.data).Resource);
        }, 500);
      }
    });
    setLoading(false);
  };
  const getEPSToken = (resId, options, callback) => {
    console.log("action", studentStudyGroupId)
    //setfetchingEPSResourceToken(true);
    var value = {
        ResourceId: resId,
    };
    try {
        if (role == "S") {
            var variables = {
                StudyGroupId: options.studentClassProperty,
                ResourceId: resId,
                SchoolYearId: selectedSchoolYear.SchoolYearId,
            };
            fetchQuery(
                environment,
                UNIT_PAGE_GET_EPS_TOKEN_STUDENT_QUERY,
                variables
            ).then((data) => {
                if (options.platform == "Web") {
                    forWebAction(data, options, callback);
                }
                if (options.platform == "App") {
                    // this.forAppAction(data, options, callback);
                    callback(data);
                }
            });
        } else {
            fetchQuery(environment, UNIT_PAGE_GET_EPS_TOKEN_QUERY, value).then(
                (data) => {
                    if (options.platform == "Web") {
                        forWebAction(data, options, callback);
                    }
                    if (options.platform == "App") {
                        // this.forAppAction(data, options, callback);
                        callback(data);
                    }
                }
            );
        }
    } catch (e) {
       // setfetchingEPSResourceToken(false);
        //setLoading(false);
        // setresourceDataLoading(false);

        window.alert(e.message);
    }
};
const forWebAction = (data, options, callback) => {
  try {
      switch (options.restype) {
          case "IMAGE":
              if (options.actionType == "download") {
                  var a = document.createElement("a");
                  a.href = DOMPurify.sanitize(data.resourceSearch.Resources[0].ResourceAccessUrl);
                  a.target = "_blank";
                  a.download = options.actionType;
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                  a.click();
                  a.remove(); //afterwards we remove the element again

                  //setfetchingEPSResourceToken(false);
              } else {
                  callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
                  // var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
              }

              break;
          default:
              if (options.actionType == "download") {
                  var a = document.createElement("a");
                  a.href = DOMPurify.sanitize(data.resourceSearch.Resources[0].ResourceAccessUrl);
                  a.target = "_blank";
                  a.download = options.actionType;
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

                  a.click();
                  a.remove(); //afterwards we remove the element again
              } else {
                  var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
                  systemBrowserOpenLink(url);
              }

              //setfetchingEPSResourceToken(false);
              break;
      }
  } catch (e) {
     // setfetchingEPSResourceToken(false);
      window.alert(e.message);
  }
};
  const QRCodeOpenResource = (data, bookseriesId, bookId, studentClassIdArg) => {

    //alert(data.ResourceType)
    let actionData = action.data;
    if (role == "S") {
      if(actionData.strucId != 0){
        var variables = {
          StructureId: actionData.strucId,
          ResourceId: data.ResourceId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        ReadResourceMutation(variables, (data) => { });
      }
      
    }
    if (
      !window.cordova &&
      (isBrowser ||
        isChrome ||
        isFirefox ||
        isSafari ||
        isOpera ||
        isIE ||
        isEdge ||
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        ))
    ) {
      if (isChrome) {
        console.log("[App] device Browser: isChrome");
      }
      if (isFirefox) {
        console.log("[App] device Browser: isFirefox");
      }
      if (isSafari) {
        console.log("[App] device Browser: isSafari");
      }
      if (isOpera) {
        console.log("[App] device Browser: isOpera");
      }
      if (isIE) {
        console.log("[App] device Browser: isIE");
      }
      if (isEdge) {
        console.log("[App] device Browser: isEdge");
      }
      if (
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        )
      ) {
        console.log(
          "[App] device Browser: should be samsung",
          navigator.userAgent
        );
      }

      //check is app only and no web

      switch (data.ResourceType) {
        case "MASTERWEBITE":
            return HandleSSOtoOtherPlatform("WEB", {
              platform: "web",
              url: data.ResourceAccessUrl,
            });
           break;
        case "YOUTUBE":
          handleLightBox({ ...data, type: "youtube", obj: data });
          break;
        case "VIMEO":
          handleLightBox({ ...data, type: "vimeo", obj: data });
          break;
        case "IMAGE":
          handleLightBox({ ...data, type: "image", obj: data });
          break;
        case "LEARNOSITY":
          const newURL =
            "/a/studygroup/" +
            bookseriesId +
            "/assessment/qs&" +
            bookId +
            "/" +
            data.ResourceId +
            "/start";
          window.open(newURL, "_self");
          break;
        case "PRIZM":
          const newURLP =
            "/a/studygroup/" +
            bookseriesId +
            "/assessment/prizm&" +
            bookId +
            "/" +
            data.ResourceId +
            "/start";
          window.open(newURLP, "_self");
          break;
        case "WEB_LINK":
        case "VIDEO":
        case "DOCUMENT":
        case "AUDIO":
        case "EBOOK4":
        case "OTHER":
          if (data.FileSource == "eps") {
            // eps download or open
            getEPSToken(data.ResourceId, {
              actionType: "open",
              restype: data.ResourceType,
              platform: "Web",
              studentClassProperty: studentClassIdArg
            });
          } else {
            window.open(data.ResourceAccessUrl, "_blank");
          }
        break;
        case "INTERACTIVE":
          getEPSToken(data.ResourceId, {
            actionType: "open",
            restype: data.ResourceType,
            platform: "Web",
            studentClassProperty: studentClassIdArg
          });
        break;
        default:
          console.log(data);
          window.open(data.ResourceAccessUrl, "_blank");

          break;
      }

      //only web
    }
    else if (window.cordova) {
        console.log("HERE IS DATA: ", JSON.stringify(data));
            switch (data.ResourceType) {
                case "WEB_LINK":
                case "VIDEO":
                case "DOCUMENT":
                case "AUDIO":
                case "OTHER":
                    if (data.FileSource === "eps") {
                        // eps download or open
                        getEPSToken(data.ResourceId, {
                            actionType: "open",
                            restype: data.ResourceType,
                            platform: "Web",
                            studentClassProperty: studentClassIdArg
                        });
                    } else {
                        systemBrowserOpenLink(data.ResourceAccessUrl);
                    }
                    break;
                case "EBOOK4":
                    systemBrowserOpenLink(data.ResourceAccessUrl);
                    break;
                case "IMAGE":
                    handleLightBox({ ...data, type: "image", obj: data });
                    break;

                case "YOUTUBE":
                    handleLightBox({...data, type: "youtube", obj: data});
                    break;
                case "VIMEO":
                    handleLightBox({...data, type: "vimeo", obj: data});
                    break;
                    //https://pec.dev.pearson.com.hk/a/studygroup/801/assessment/qs&432/10893/start
                case "LEARNOSITY":
                    const newURL =
                            "/a/studygroup/" +
                            bookseriesId +
                            "/assessment/" +
                            "qs&" +
                            bookId +
                            "/" +
                            data.ResourceId +
                            "/start";
                    props.history.push(newURL);
                    break;
                case "PRIZM":
                    console.log("isIOS? ", isIOS);
                    if (isIOS) {
                        iosOpenPrizm(props, data.ResourceLangs[0]?.ResourceName, data.ResourceId, "",  selectedSchoolYear.SchoolYearId, encrypt(userCredential), data);
                        return;
                    }
                    const newURLP =
                        "/a/studygroup/" +
                        bookseriesId +
                        "/assessment/prizm&" +
                        bookId +
                        "/" +
                        data.ResourceId +
                        "/start";
                    props.history.push(newURLP);
                    break;
                case "INTERACTIVE":
                    const cookie = data.ResourceAccessUrl.split("/index.html?")[1];
                    const cookieKey = cookie.split('=')[0];
                    const browserRef = window.cordova.InAppBrowser.open(data.ResourceAccessUrl, "_blank", "hidden=yes");
                    browserRef.addEventListener("loadstop", function() {
                        browserRef.executeScript({
                            code: `if (!document.cookie || document.cookie.indexOf('${cookieKey}') === -1) {
                                        document.cookie = '${cookie}';
                                        location.reload();
                                    } else {
                                        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "showIAB"}));
                                    }`
                        })
                    })

                    browserRef.on('message', function(params) {
                        if (params.data.action === "showIAB") {
                            browserRef.show();
                        }
                    })

                    break;
                case "MASTERWEBITE":
                    return HandleSSOtoOtherPlatform(isIOS ? "IOS" : "ANDROID", {
                        platform: "web",
                        url: data.ResourceAccessUrl,
                    });
                default:
                    if (data.FileSource == "eps") {
                        // eps download or open
                        getEPSToken(data.ResourceId, {
                            actionType: "open",
                            restype: data.ResourceType,
                            platform: "Web",
                        });
                    } else {
                        systemBrowserOpenLink(data.ResourceAccessUrl);
                    }
                    break;
            }
    }
    localStorage.removeItem("_action");
    setqrCodeMessageModal(messageBoxInitState);
  };

  const handleLightBox = (data) => {
    if (!data) return setLightBoxData(null);
    switch (data.type) {
      case "youtube":
      case "vimeo":
        setLightBoxData({
          type: data.type,
          videoPath: data.Linkage,
          isOpen: true,
        });

        break;
      case "image":
        setLightBoxData({
          id: data.ResourceId,
          image: data.ResourceAccessUrl,
          obj: data,
          isOpen: true,
        });
        break;
      default:
        break;
    }
  };

  const renderLightBox = () => {
    let tpye = resourceData.ResourceType;
    switch (tpye) {
      case "VIMEO":
      case "YOUTUBE":
        return (
          <VidoeLightBox
            handleVideoBox={handleLightBox}
            VideoLightBoxOpenState={LightBoxData}
          />
        );

      case "IMAGE":
        return (
          <ImageLightBox
            isOpen={LightBoxData?.isOpen ? true : false}
            handleImageBox={handleLightBox}
            ImageLightBoxOpenState={LightBoxData}
            downloadImage={async () => {
              await GetEPSToken({
                ResourceId: LightBoxData.obj.ResourceId,
              }).then((data) => {
                try {
                  gaTrack(
                    "send",
                    "event",
                    "Resource center",
                    "Download resource",
                    "Resource:" +
                      LightBoxData.obj.ResourceLangs[0].ResourceName +
                      ", Resource ID:" +
                      LightBoxData.obj.IngestionId +
                      ", Book Series:" +
                      "NoData" +
                      ", View:" +
                      "NoData" +
                      ", Resource Level:" +
                      "NoData" +
                      ", Resource Page:" +
                      "NoData" +
                      ", Class Level:" +
                      "NoData" +
                      ", Role:" +
                      role +
                      ", From:" +
                      "QRCode"
                  );
                } catch (error) {
                  console.log(error);
                }
                window.open(
                  data.resourceSearch.Resources[0].ResourceAccessUrl,
                  window.cordova ? "_system" : "_blank"
                );
              });
            }}
          />
        );
      default:
        return "";
    }
  };

  // if (!qrCodeMessageModal.isOpen && !haveOpenAction) return "";
  // if (!qrCodeMessageModal.isOpen && haveOpenAction)
  //   return (
  //     <>
  //       {resourceData &&
  //         haveOpenAction &&
  //         renderLightBox(resourceData.ResourceType)}
  //     </>
  //   );

  return (
    <>
      {/* {accessLoading && <Loading type="normal" />} */}
      <MessageLightBox
        isOpen={qrCodeMessageModal.isOpen}
        type= {noaccess? "accessRight" : "accessRightGC"}
        title={qrCodeMessageModal.title}
        message={qrCodeMessageModal.message}
        onClose={() => {
          qrCodeMessageModal.onCancel();
          localStorage.removeItem("_action");
        }}
        customButton={() => {
          if (qrCodeMessageModal.customButton == null) return null;
          return qrCodeMessageModal.customButton();
        }}
        customResourceLink={() => {
          if (qrCodeMessageModal.customResourceLink == null) return null;
          return qrCodeMessageModal.customResourceLink();
        }}
        //accessFlag={accessFlag}
        t={t}
      />
      {resourceData ? renderLightBox() : ""}
    </>
  );
};

export default GoogleCodeModal;
