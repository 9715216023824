import React, {useState, useEffect} from "react";
import Loading from "../../../Components/Loading/PEC/Loading";
import ViewNotificationPopUp from "./ViewNotificationPopUp";
import ViewStudentAttemptHistoryPopUp from "./ViewStudentAttemptHistoryPopUp";
import ViewNotificationAlreadySetPopUp from "./ViewNotificationAlreadySetPopUp";
import ResourceIconMapping from "../../../Components/Feature/Function/ResourceIconMappingPEC";
import {
    UNIT_PAGE_STRUCTURE_RESOURCES_LIST_QUERY,
    UNIT_PAGE_STRUCTURE_LIST_QUERY,
    UNIT_PAGE_GET_EPS_TOKEN_QUERY,
    UNIT_PAGE_GET_EPS_TOKEN_STUDENT_QUERY
} from "../../../constants/GraphQLQuery";
import environment from "../../../Environment";
import {fetchQuery, graphql} from "relay-runtime";
import {useSelector, useDispatch} from "react-redux";
import VidoeLightBox from "../../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../../Components/Feature/ImageLightBox";
import { config } from "../../../Config/config";
import HandleSSOtoOtherPlatform from "../../../Services/Common/HandleSSOtoOtherPlatform";
import useEncryption from "../../../hooks/useEncryption";
import {
    isMobile,
    isBrowser,
    isTablet,
    isMobileOnly,
    osName,
    isAndroid,
    isIOS,
    isChrome,
    isSafari,
    isFirefox,
    isEdge,
    isIE,
    isOpera,
} from "react-device-detect";
import "./index.scss";
import ReadResourceMutation from "../../../Components/Feature/Function/ReadResourceMutation";
import UnitListGetSelfLearnConfig from "../../../Services/api/GraphQL/Query/UnitListGetSelfLearnConfig";

import UpdateSelfLearnConfigMutation from "../../../Services/api/GraphQL/Mutation/UpdateSelfLearnConfigMutation";
import useInAppBrowser from "../../../hooks/useInAppBrowser";
import gClassroomLogo32 from "../../../assets/img/google-classroom-32.png";
import useIosOpenPrizm from "../../../hooks/useIosOpenPrizm";
//import useEncryption from "../../../hooks/useEncryption";
import tickIcon from "../../../assets/img/pec/all-images/icon-tick.svg"
import useIosOpenLearnosity from "../../../hooks/useIosOpenLearnosity";
import useGATracking from "../../../hooks/useGATracking";

const UnitPage = (props) => {
    const {
        t,
        selectedBookSeriesId,
        selectedBookId,
        ClassId,
        selectedClass,
        showSDLPage,
        setShowSDLPage,
        getScoreAndAverageClass,
        retainChapterFunc,
    } = props;
    const userData = useSelector((state) => state.UserReducer.user);
    const UserType = userData?.UserType;
    const userSchool = userData?.School?.SchoolCode;
    const userClass = userData?.BelongsToStudyGroup?.StudyGroup?.StudyGroupName[0]?.StudyGroupName;
    const dispatch = useDispatch();
    const { openLearnosity } = useIosOpenLearnosity();
    const lang = useSelector((state) => state.SystemReducer.lang);
    const selectedSchoolYear = useSelector(
        (state) => state.SystemReducer.selectedSchoolYear
    );
    const selectOptions = useSelector(
        (state) => state.EntryOfAssessmentReducer.selectOptions
    );
    const role = localStorage.getItem("role");
    //Test
    //alert(role)
    const effectiveSchoolYear = useSelector(
        (state) => state.SystemReducer.effectiveSchoolYear
    );
    const [activeBook, setActiveBook] = useState(
        selectOptions.selectecResourceTab
            ? selectOptions.selectecResourceTab
            : 1
    );
    const [activeBookName, setActiveBookName] = useState(1);
    const [activeSubUnitSectionName, setActiveSubUnitSectionName] = useState("");
    const [resourceDetails, setResourceDetails] = useState([]);
    const [openedUnitSection, setOpenedUnitSection] = useState(1);
    const [openedUnitSectionFlag, setOpenedUnitSectionFlag] = useState(true);
    const [isViewable, setIsViewable] = useState(true);
    const [unitLoadingHere, setUnitLoadingHere] = useState(true);
    const [unitSubLoading, setUnitSubLoading] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showNotificationPopUp, setShowNotificationPopUp] = useState(false);
    const [resourceCenterStructureList, setresourceCenterStructureList] =
        useState([]);
    const [tabClassId, settabClassId] = useState(ClassId);
    const [subUnitListing, setsubUnitListing] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [unitItemLoading, setUnitItemLoading] = useState(false);
    const [hideShowLoading, setHideShowLoading] = useState(false);
    const [attemptStudentInfo, setattemptStudentInfo] = useState([]);
    const [resourceNamePop, setresourceNamePop] = useState("");

    const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
        useState(false);
    //Show Hide Functional
    const {toggleProgress, refreshBtn} = props;
    //const {encrypt, decryptToLocal} = useEncryption();
    const [studyGroupData, setstudyGroupData] = useState(null);

    const [final, setfinal] = useState([]);
    const [mainListRefresh, setmainListRefresh] = useState(0);

    //Show Hide Functional
    const [videoLightBoxOpen, setVideoLightBoxOpen] = useState(null);
    const [imageLightBoxOpen, setImageLightBoxOpen] = useState(null);
    const {systemBrowserOpenLink, openLink} = useInAppBrowser();
    const {iosOpenPrizm, iosOpenPrizmSubmission} = useIosOpenPrizm();
    const {encrypt, decryptToLocal} = useEncryption();
    const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;

    const [showAttempt, setShowAttempt] = useState(false)
    const [showPageLoading, setShowPageLoading] = useState(false)
    const [defaultMsg, setDefaultMsg] = useState(false)
    const { sendGaEvent } = useGATracking();
    const [fullResourceCenterStructureData, setFullResourceCenterStructureData] = useState(undefined)

    //const [resourceDataLoading, setresourceDataLoading] = useState(false);
    //const [originData, setoriginData] = useState([]);
    //const [selectAssign, setselectAssign] = useState([]);
    //const [loading, setLoading] = useState(true);
    //const [studyGroupList, setstudyGroupList] = useState([]);

    // view settings code starts here
    const submitShowHide = (showFlag) => {
        console.log("data", resourceDetails)
        var data = final;
        const callback = (result) => {
            setShowPopUp(false);
            // reSettingup();
            //test
            if (!window.cordova) {
                window.gtag('event', 'SDL - Confirm show hide setting for resource', {
                    'Resource': activeSubUnitSectionName,
                    'Resource_ID': resourceDetails.IngestionId,
                    'Resource_Type': resourceDetails.ResourceType,
                    'Book_Series': props.selectedBookSeriesName,
                    'Book': props.selectedBookName,
                    'Unit': activeBookName,
                    // 'Class_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName,
                    'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                    'user_Role': UserType,
                    'Action': 'Show',
                    'Notification': showFlag ? 'Yes' : 'No'
                    })
            } else {
                // sendGaEvent('SDL - Confirm show hide setting for resource', {
                //     'Resource': activeSubUnitSectionName,
                //     'Resource_ID': resourceDetails.IngestionId,
                //     'Resource_Type': resourceDetails.ResourceType,
                //     'Book_Series': props.selectedBookSeriesName,
                //     'Book': props.selectedBookName,
                //     'Unit': activeBookName,
                //     // 'Class_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName,
                //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                //     'user_Role': UserType,
                //     'Action': 'Show',
                //     'Notification': showFlag ? 'Yes' : 'No'
                // })
            }
            fetchResources(data[0].StructureId, 1);
        };
        UpdateSelfLearnConfigMutation(data, callback, "resource", showFlag);
        //setActiveBook(activeBook);
        //setOpenedUnitSection(openedUnitSection);
        //fetchResources(openedUnitSection);
    };

    const onClickGoogleClassroom = (id, IngestionId, strucId,unitName) => {
        console.log('inside class room - onClickGoogleClassroom')
        var chapterNameParam =activeBookName.replace(/ /g,"_");
        var unitNameParam =unitName.replace(/ /g,"_");
        const returnURL =
            config.createGCURL.redirctURL +
            "action=GOOGLE_CLASS&resId=" +
            IngestionId +
            "&BOOK_SERIES=" +
            props.match.params.id +
            "&BOOK=" +
            selectedBookId +"&STRUCTURE_ID=" + strucId+ "&CHAPTER="+ encodeURIComponent(chapterNameParam) + "&UNIT=" + encodeURIComponent(unitNameParam);
        console.log("suresh", returnURL)
            systemBrowserOpenLink("https://classroom.google.com/share?url=" + encodeURIComponent(returnURL));
        // https://pec.dev.pearson.com.hk/app/sso?action=GOOGLE_CLASS&resId=Edge_2E_1BU5_R1C1_SbRPEx&BOOK_SERIES=801&BOOK=431&STRUCTURE_ID=1422&CHAPTER=Unit_5_Tasty_treats!&UNIT=Reading_1_%25_Comprehension_1
    }


    // useEffect(() => {
    //   if(settingsUp !=0){
    //     fetchResources(openedUnitSection);
    //   }

    // }, [settingsUp]);

    const renderWidget = (id, IngestionId, strucId, unitName) => {
        //console.log("today", unitName,activeBookName)
        // console.log('inside class room - renderWidget')
        console.log('inside class room - renderWidget')
        var chapterNameParam =activeBookName.replace(/ /g,"_");
        var unitNameParam =unitName.replace(/ /g,"_");
        let returnURL =
            config.createGCURL.redirctURL +
            "action=GOOGLE_CLASS&resId=" +
            IngestionId +
            "&BOOK_SERIES=" +
            props.match.params.id +
            "&BOOK=" +
            selectedBookId +"&STRUCTURE_ID=" + strucId + "&CHAPTER="+ encodeURIComponent(chapterNameParam) + "&UNIT=" + encodeURIComponent(unitNameParam);
//console.log("Test", encodeURIComponent(returnURL))
        gapi.sharetoclassroom.render(id, {url: returnURL});
    };





    // view settings code ends here

    const getResourcesCenterStructureList = () => {
        var variables = {
            UserId: userData.UserId,
            BookSeriesId: selectedBookSeriesId,
            StudyGroupId: ClassId || "",
            SchoolYearId: selectedSchoolYear.SchoolYearId,
            BookSeriesIds: [selectedBookSeriesId],
        };
        return fetchQuery(environment, UNIT_PAGE_STRUCTURE_LIST_QUERY, variables);
    };

    // useEffect(() => {
    // }, [resourceCenterStructureList]);

    const confirmDataClickable = (obj, activeFetchId, isView, isPopup) => {
        //alert("coming")
        //console.log("HERE IS SDF"+ window.cordova ? encrypt(userCredential) : localStorage.getItem("IESToken"))
        var variables = {
            StudyGroupId: ClassId || "",
            StructureId: activeFetchId,
            SchoolYearId: selectedSchoolYear.SchoolYearId,
            PageNo: 1,
            UserId: JSON.parse(localStorage.getItem("userData")).UserId,
            PageSize: 20,
            StudyGroupIds: ClassId == null ? [] : [ClassId],
            Credential: window.cordova ? encrypt(userCredential) : null
        };

        fetchQuery(
            environment,
            UNIT_PAGE_STRUCTURE_RESOURCES_LIST_QUERY,
            variables
        ).then((data) => {
            // if (!data?.resourceCenterStructure) {
            //   return props.history.push("/m/myschool");
            // }
            // if (!data.resourceCenterStructure.RelatedResources) {
            //   return props.history.push("/m/myschool");
            // }

            // var userType = JSON.parse(localStorage.getItem("userData")).UserType;
            var predata = [];
            // if (userType == "T") {
            if (data.resourceCenterStructure.RelatedResources.Resources != null)
                predata = [...data.resourceCenterStructure.RelatedResources.Resources];

            let csres = predata.filter((o) => o.ResourceId == obj.ResourceId);

            var currentSettting = csres[0];

            if (
                obj.SelfLearnConfiguration.map((i) => {
                    return i.ConfigName;
                }).indexOf("ShowHide") ==
                currentSettting.SelfLearnConfiguration.map((i) => {
                    return i.ConfigName;
                }).indexOf("ShowHide")
            ) {
                if (
                    obj.SelfLearnConfiguration.map((i) => {
                        return i.ConfigName;
                    }).indexOf("ShowHide") != -1
                ) {
                    if (
                        obj.SelfLearnConfiguration[
                            obj.SelfLearnConfiguration.map((i) => {
                                return i.ConfigName;
                            }).indexOf("ShowHide")
                            ].ConfigValue ==
                        currentSettting.SelfLearnConfiguration[
                            currentSettting.SelfLearnConfiguration.map((i) => {
                                return i.ConfigName;
                            }).indexOf("ShowHide")
                            ].ConfigValue
                    ) {
                        setHideShowLoading(false);
                        setIsViewable(isView);
                        setShowPopUp(isPopup);
                    } else {
                        setHideShowLoading(false);
                        setShowNotificationPopUp(true);
                    }
                } else {
                    setHideShowLoading(false);
                    setIsViewable(isView);
                    setShowPopUp(isPopup);
                }
            } else {
                alert("Action Not Allowed");
            }

            // return false;
        });
    };

    useEffect(() => {
        getResourcesCenterStructureList().then((data) => {
            setFullResourceCenterStructureData(data)
        })
    }, [])

    useEffect(() => {
        // console.log('inside class room - useeffect')
        if (ClassId != "" && selectedBookId != "" && (fullResourceCenterStructureData != undefined)) {
            //alert(ClassId)
//alert(selectedBookId)
            // getResourcesCenterStructureList().then((data) => {
            const transformData = (data) => {
                //setoriginData([]);
                // setresourceCenterStructureList([]);

                const {
                    user: {AdoptedBookSeries, School, BelongsToStudyGroup} = {},
                } = data || {};

                let findOnlyOneStudyId;

                //const booksList = AdoptedBookSeries[0];

                let booksListT = data.user.AdoptedBookSeries[0].AdoptedBooks.filter(
                    (o) => o.BookId == selectedBookId
                );

                let booksList = booksListT[0];
                // const studyId =
                //   tabClassId == null
                //     ? UserType == "T"
                //       ? School.StudyGroups[0].StudyGroupId
                //       : BelongsToStudyGroup[0].StudyGroup.StudyGroupId
                //     : tabClassId;
                if (UserType == "S") {
                    const reduceStudyGroup = BelongsToStudyGroup.reduce((acc, cur) => {
                        const {StudyGroup: {Level, StudyGroupId, StudyGroupName} = {}} =
                            cur;
                        acc.push({
                            Level,
                            StudyGroupId,
                            StudyGroupName,
                        });
                        return acc;
                    }, []);

                    findOnlyOneStudyId = reduceStudyGroup.filter((o) => {
                        return o.StudyGroupId === ClassId;
                    });
                } else {
                    findOnlyOneStudyId = School.StudyGroups.filter((o) => {
                        return o.StudyGroupId === ClassId;
                    });
                }
                const mixedData = {
                    ...findOnlyOneStudyId[0],
                    BookSeries: {
                        BookSeriesName: booksList.BookSeriesName,
                    },
                };

                var preSetData = [],
                    topLevelIds = [],
                    callback = JSON.parse(
                        JSON.stringify(booksList.ResourceCenterStructure)
                    );

                preSetData.push({
                    book: {
                        bookseriesId: booksList.BookSeriesId,
                        DisplaySequence: booksList.DisplaySequence,
                        BookSeriesName: booksList.BookSeriesName,
                        options: [],
                    },
                });
                callback.map((robj, rinde) => {
                    topLevelIds.push(robj.StructureId);
                    //get all parent first
                    if (robj.ParentStructureId == null || robj.ParentStructureId == "") {
                        preSetData[0].book.options.push(robj);
                    }
                });

                preSetData[0].book.options.map((oobj, ooindex) => {
                    var tempSave = oobj.ChildStructure,
                        newChil = [];
                    tempSave.map((tc, tindex) => {
                        if (topLevelIds.indexOf(tc.StructureId) != -1) {
                            newChil.push(tc);
                        }
                    });
                    oobj.ChildStructure = newChil;
                    oobj.ChildStructure.map((cobj, cindex) => {
                        if (
                            callback
                                .map((i) => {
                                    return i.ParentStructureId;
                                })
                                .indexOf(cobj.StructureId) != -1 &&
                            topLevelIds.indexOf(oobj.StructureId) != -1
                        ) {
                            callback.map((i) => {
                                if (
                                    i.ParentStructureId == cobj.StructureId &&
                                    topLevelIds.indexOf(i.StructureId) != -1
                                ) {
                                    if (!cobj.ChildStructure) {
                                        cobj.ChildStructure = [];
                                        cobj.ChildStructure.push(i);
                                    } else {
                                        cobj.ChildStructure.push(i);
                                    }
                                }
                            });
                        }
                    });
                });
                preSetData.map((i) => {
                    i.book.options.sort((a, b) => {
                        return parseInt(a.DisplaySequence) - parseInt(b.DisplaySequence);
                    });
                });
                preSetData.map((obj, index) => {
                    obj.book.options.map((oobj, oindex) => {
                        if (
                            oobj.ParentStructureId == null ||
                            oobj.ParentStructureId == ""
                        ) {
                        }
                    });
                });

                if (preSetData[0].book.options.length > 0) {
                    if (selectOptions.selectecResourceTab) {
                        if (topLevelIds.includes(selectOptions.selectecResourceTab)) {
                            setActiveBook(selectOptions.selectecResourceTab);
                            setActiveBookName(selectOptions.selectedResourceName);
                            var currentSection = preSetData[0].book.options.filter(
                                (o) => o.StructureId == selectOptions.selectecResourceTab
                            );
                            var allSubUnitInfo = [];

                            currentSection[0].ChildStructure.map((oobj, oindex) => {
                                var summaryInfoSub = JSON.parse(oobj.SummaryData);

                                if (summaryInfoSub.avgScore === null) {
                                    var obj = {
                                        parentId: oobj.ParentStructureId,
                                        info: oobj,
                                        structureId: oobj.StructureId,
                                        avgScore: "",
                                        readAvg: "-",
                                        submittedAvg: "-",
                                        details: [],
                                    };
                                } else {
                                    var obj = {
                                        parentId: oobj.ParentStructureId,
                                        info: oobj,
                                        structureId: oobj.StructureId,
                                        avgScore: Math.round(summaryInfoSub.avgScore),
                                        readAvg: "-",
                                        submittedAvg: "-",
                                        details: [],
                                    };
                                }

                                allSubUnitInfo.push(obj);
                            });
                            setsubUnitListing(allSubUnitInfo);
                        } else {
                            setActiveBookName(
                                preSetData[0].book.options[0].StructureLang[
                                    preSetData[0].book.options[0].StructureLang[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StructureTitle
                            );
                            setActiveBook(preSetData[0].book.options[0].StructureId);
                            var allSubUnitInfo = [];
                            preSetData[0].book.options[0].ChildStructure.map(
                                (oobj, oindex) => {
                                    var summaryInfoSub = JSON.parse(oobj.SummaryData);

                                    if (summaryInfoSub.avgScore === null) {
                                        var obj = {
                                            parentId: oobj.ParentStructureId,
                                            info: oobj,
                                            structureId: oobj.StructureId,
                                            avgScore: "",
                                            readAvg: "-",
                                            submittedAvg: "-",
                                            details: [],
                                        };
                                    } else {
                                        var obj = {
                                            parentId: oobj.ParentStructureId,
                                            info: oobj,
                                            structureId: oobj.StructureId,
                                            avgScore: Math.round(summaryInfoSub.avgScore),
                                            readAvg: "-",
                                            submittedAvg: "-",
                                            details: [],
                                        };
                                    }

                                    allSubUnitInfo.push(obj);
                                }
                            );
                            setsubUnitListing(allSubUnitInfo);
                        }
                    } else {
                        setActiveBookName(
                            preSetData[0].book.options[0].StructureLang[
                                preSetData[0].book.options[0].StructureLang[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StructureTitle
                        );
                        setActiveBook(preSetData[0].book.options[0].StructureId);
                        var allSubUnitInfo = [];
                        preSetData[0].book.options[0].ChildStructure.map((oobj, oindex) => {
                            var summaryInfoSub = JSON.parse(oobj.SummaryData);

                            if (summaryInfoSub.avgScore === null) {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: "",
                                    readAvg: "-",
                                    submittedAvg: "-",
                                    details: [],
                                };
                            } else {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: Math.round(summaryInfoSub.avgScore),
                                    readAvg: "-",
                                    submittedAvg: "-",
                                    details: [],
                                };
                            }

                            allSubUnitInfo.push(obj);
                        });
                        setsubUnitListing(allSubUnitInfo);
                    }
                }

                if (preSetData[0].book.options.length == 0) {
                    setShowSDLPage(true);
                } else {
                    setShowSDLPage(false);
                }

                setresourceCenterStructureList(preSetData);
                //setoriginData(callback);
                setUnitLoadingHere(false);
                setUnitSubLoading(false);
                setstudyGroupData(mixedData);
            }

            // only refetch if all of the following condition are met
            // 1. user is a teacher
            // 2. view progress is turned on
            if (UserType == "T" && toggleProgress) {
                setOpenedUnitSectionFlag(false);
                setUnitSubLoading(true);
                setUnitLoadingHere(true);
                settabClassId(ClassId);
                getResourcesCenterStructureList().then((data) => {
                    transformData(data)
                })
            } else {
                transformData(fullResourceCenterStructureData)
            }

                // initGuide(skipGuide, skipMenuSteps);
            // });
        }

        //setActiveBookName(BookLists[0].unit);
    }, [ClassId, selectedBookId, fullResourceCenterStructureData, toggleProgress]);
    // }, []);
    // useEffect(() => {
    //     if (mainListRefresh != 0) {
    //         refreshContent();
    //     }
    //     //setActiveBookName(BookLists[0].unit);
    // }, [mainListRefresh]);

    // useEffect(() => {
    //   if(role != "student"){
    //     if (refreshBtn) {
    //       refreshContent();
    //     }
    //   }
    // }, [refreshBtn]);

    useEffect(() => {
        retainChapterFunc(activeBookName,1,"yes")
      }, [activeBookName]);




    const refreshContent = () => {
        // console.log('inside class room - refreshContent')
        if (ClassId != "" && selectedBookId != "") {
            setOpenedUnitSectionFlag(false);
            setUnitSubLoading(true);
            //setUnitItemLoading(true);
            setUnitLoadingHere(true);
            settabClassId(ClassId);
            getResourcesCenterStructureList().then((data) => {
                //setoriginData([]);
                // setresourceCenterStructureList([]);
                const {
                    user: {AdoptedBookSeries, School, BelongsToStudyGroup} = {},
                } = data || {};

                let findOnlyOneStudyId;

                //const booksList = AdoptedBookSeries[0];

                let booksListT = data.user.AdoptedBookSeries[0].AdoptedBooks.filter(
                    (o) => o.BookId == selectedBookId
                );

                let booksList = booksListT[0];
                // const studyId =
                //   tabClassId == null
                //     ? UserType == "T"
                //       ? School.StudyGroups[0].StudyGroupId
                //       : BelongsToStudyGroup[0].StudyGroup.StudyGroupId
                //     : tabClassId;
                if (UserType == "S") {
                    const reduceStudyGroup = BelongsToStudyGroup.reduce((acc, cur) => {
                        const {StudyGroup: {Level, StudyGroupId, StudyGroupName} = {}} =
                            cur;
                        acc.push({
                            Level,
                            StudyGroupId,
                            StudyGroupName,
                        });
                        return acc;
                    }, []);

                    findOnlyOneStudyId = reduceStudyGroup.filter((o) => {
                        return o.StudyGroupId === ClassId;
                    });
                } else {
                    findOnlyOneStudyId = School.StudyGroups.filter((o) => {
                        return o.StudyGroupId === ClassId;
                    });
                }
                const mixedData = {
                    ...findOnlyOneStudyId[0],
                    BookSeries: {
                        BookSeriesName: booksList.BookSeriesName,
                    },
                };

                var preSetData = [],
                    topLevelIds = [],
                    callback = JSON.parse(
                        JSON.stringify(booksList.ResourceCenterStructure)
                    );

                preSetData.push({
                    book: {
                        bookseriesId: booksList.BookSeriesId,
                        DisplaySequence: booksList.DisplaySequence,
                        BookSeriesName: booksList.BookSeriesName,
                        options: [],
                    },
                });
                callback.map((robj, rinde) => {
                    topLevelIds.push(robj.StructureId);
                    //get all parent first
                    if (robj.ParentStructureId == null || robj.ParentStructureId == "") {
                        preSetData[0].book.options.push(robj);
                    }
                });

                preSetData[0].book.options.map((oobj, ooindex) => {
                    var tempSave = oobj.ChildStructure,
                        newChil = [];
                    tempSave.map((tc, tindex) => {
                        if (topLevelIds.indexOf(tc.StructureId) != -1) {
                            newChil.push(tc);
                        }
                    });
                    oobj.ChildStructure = newChil;
                    oobj.ChildStructure.map((cobj, cindex) => {
                        if (
                            callback
                                .map((i) => {
                                    return i.ParentStructureId;
                                })
                                .indexOf(cobj.StructureId) != -1 &&
                            topLevelIds.indexOf(oobj.StructureId) != -1
                        ) {
                            callback.map((i) => {
                                if (
                                    i.ParentStructureId == cobj.StructureId &&
                                    topLevelIds.indexOf(i.StructureId) != -1
                                ) {
                                    if (!cobj.ChildStructure) {
                                        cobj.ChildStructure = [];
                                        cobj.ChildStructure.push(i);
                                    } else {
                                        cobj.ChildStructure.push(i);
                                    }
                                }
                            });
                        }
                    });
                });
                preSetData.map((i) => {
                    i.book.options.sort((a, b) => {
                        return parseInt(a.DisplaySequence) - parseInt(b.DisplaySequence);
                    });
                });
                preSetData.map((obj, index) => {
                    obj.book.options.map((oobj, oindex) => {
                        if (
                            oobj.ParentStructureId == null ||
                            oobj.ParentStructureId == ""
                        ) {
                        }
                    });
                });

                if (activeBook == 1) {
                    if (preSetData[0].book.options.length > 0) {
                        setActiveBookName(
                            preSetData[0].book.options[0].StructureLang[
                                preSetData[0].book.options[0].StructureLang[0].Lang == lang
                                    ? 0
                                    : 1
                                ].StructureTitle
                        );
                        setActiveBook(preSetData[0].book.options[0].StructureId);
                        var allSubUnitInfo = [];
                        preSetData[0].book.options[0].ChildStructure.map((oobj, oindex) => {
                            var summaryInfoSub = JSON.parse(oobj.SummaryData);

                            if (summaryInfoSub.avgScore === null) {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: "",
                                    details: [],
                                };
                            } else {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: Math.round(summaryInfoSub.avgScore),
                                    details: [],
                                };
                            }
                            // var obj = {
                            //   parentId: oobj.ParentStructureId,
                            //   info: oobj,
                            //   structureId: oobj.StructureId,
                            //   avgScore: summaryInfoSub.avgScore
                            //     ? Math.round(summaryInfoSub.avgScore)
                            //     : "",
                            //   details: [],
                            // };
                            allSubUnitInfo.push(obj);
                        });
                        setsubUnitListing(allSubUnitInfo);
                    }
                } else {
                    var tempSelectionCh = activeBook;
                    var currentSection = preSetData[0].book.options.filter(
                        (o) => o.StructureId == tempSelectionCh
                    );
                    if (currentSection[0].ChildStructure.length > 0) {
                        // setActiveBook(tempSelectionCh);
                        var allSubUnitInfo = [];
                        currentSection[0].ChildStructure.map((oobj, oindex) => {
                            var summaryInfoSub = JSON.parse(oobj.SummaryData);

                            if (summaryInfoSub.avgScore === null) {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: "",
                                    details: [],
                                };
                            } else {
                                var obj = {
                                    parentId: oobj.ParentStructureId,
                                    info: oobj,
                                    structureId: oobj.StructureId,
                                    avgScore: Math.round(summaryInfoSub.avgScore),
                                    details: [],
                                };
                            }
                            // var obj = {
                            //   parentId: oobj.ParentStructureId,
                            //   info: oobj,
                            //   structureId: oobj.StructureId,
                            //   avgScore: summaryInfoSub.avgScore
                            //     ? Math.round(summaryInfoSub.avgScore)
                            //     : "",
                            //   details: [],
                            // };
                            allSubUnitInfo.push(obj);
                        });
                        setsubUnitListing(allSubUnitInfo);
                    } else {
                        setsubUnitListing([]);
                    }
                }

                if (preSetData[0].book.options.length == 0) {
                    setShowSDLPage(true);
                } else {
                    setShowSDLPage(false);
                }

                setresourceCenterStructureList(preSetData);
                //setoriginData(callback);
                setUnitLoadingHere(false);
                setUnitSubLoading(false);
                //setUnitItemLoading(false);
                setstudyGroupData(mixedData);

                // initGuide(skipGuide, skipMenuSteps);
            });
        }
    };
    const updateSubUnitListing = (structureId) => {
        // setsubUnitListing([]);
        let booksListT = resourceCenterStructureList[0].book.options.filter(
            (o) => o.StructureId == structureId
        );
        var allSubUnitInfo = [];

        if (booksListT.length > 0) {
            booksListT[0].ChildStructure.map((oobj, oindex) => {
                var summaryInfoSub = JSON.parse(oobj.SummaryData);
                var avgScoreSummary = "";
                if (summaryInfoSub.avgScore === null) {
                    avgScoreSummary = "";
                } else {
                    avgScoreSummary = Math.round(summaryInfoSub.avgScore);
                }
                var obj = {
                    parentId: oobj.ParentStructureId,
                    info: oobj,
                    structureId: oobj.StructureId,
                    avgScore: avgScoreSummary,
                    readAvg: "-",
                    submittedAvg: "-",
                    details: [],
                };
                allSubUnitInfo.push(obj);
            });
            setsubUnitListing(allSubUnitInfo);
        }
    };
    const getUnitListingsData = (Id) => {
        // var obj = {
        //   usectionName: "Example for Demo 1 (Learnetic)",
        //   icon: "item-video-image",
        //   submitted: 15.68,
        //   viewed: 25.68,
        //   isViewable: true,
        //   avgScore: 18,
        // };
        // var allData = [...unitListingData];
        // var latestData = unitListingData.findIndex((x) => x.unitId == Id);
        // if (allData[latestData].details.length == 0)
        //   allData[latestData].details.push(obj);
        // setUnitListingData(allData);
    };

    const forWebAction = (data, options, callback) => {
        try {
            switch (options.restype) {
                case "IMAGE":
                    if (options.actionType == "download") {
                        var a = document.createElement("a");
                        a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
                        a.target = "_blank";
                        a.download = options.actionType;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove(); //afterwards we remove the element again

                        setfetchingEPSResourceToken(false);
                    } else {
                        callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
                        // var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
                    }

                    break;
                default:
                    if (options.actionType == "download") {
                        var a = document.createElement("a");
                        a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
                        a.target = "_blank";
                        a.download = options.actionType;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

                        a.click();
                        a.remove(); //afterwards we remove the element again
                    } else {
                        var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
                        if (!window.cordova) {
                            window.open(url, "_blank");
                        } else {
                            systemBrowserOpenLink(url);
                        }
                        // systemBrowserOpenLink(url);
                    }

                    setfetchingEPSResourceToken(false);
                    break;
            }
        } catch (e) {
            setfetchingEPSResourceToken(false);
            window.alert(e.message);
        }
    };

    const getEPSToken = (resId, options, callback) => {
       // setfetchingEPSResourceToken(true);
        var value = {
            ResourceId: resId,
        };
        try {
            if (UserType == "S") {
                var variables = {
                    StudyGroupId: tabClassId,
                    ResourceId: resId,
                    SchoolYearId: selectedSchoolYear.SchoolYearId,
                };
                fetchQuery(
                    environment,
                    UNIT_PAGE_GET_EPS_TOKEN_STUDENT_QUERY,
                    variables
                ).then((data) => {
                    if (options.platform == "Web") {
                        forWebAction(data, options, callback);
                    }
                    if (options.platform == "App") {
                        // this.forAppAction(data, options, callback);
                        callback(data);
                    }
                });
            } else {
                fetchQuery(environment, UNIT_PAGE_GET_EPS_TOKEN_QUERY, value).then(
                    (data) => {
                        if (options.platform == "Web") {
                            forWebAction(data, options, callback);
                        }
                        if (options.platform == "App") {
                            // this.forAppAction(data, options, callback);
                            callback(data);
                        }
                    }
                );
            }
        } catch (e) {
            setfetchingEPSResourceToken(false);
            //setLoading(false);
            // setresourceDataLoading(false);

            window.alert(e.message);
        }
    };

    const handleVideoBox = (data) => {
        setVideoLightBoxOpen(
            data
                ? {
                    type: data.type,
                    videoPath: data.Linkage,
                    isOpen: true,
                }
                : null
        );
    };
    const handleImageBox = (id, path) => {
        setImageLightBoxOpen(id ? {name: id, image: path} : id);
    };

  const checkDeviceToOpenOrDownload = async (data, type) => {
      console.log("HERE IS RESOURCE TYPE: ", data.ResourceType)
      console.log("HERE IS URL: ", data.ResourceAccessUrl)
    if (type == "open") {
      if (UserType == "S") {
        var variables = {
          StructureId: openedUnitSection,
          ResourceId: data.ResourceId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        ReadResourceMutation(variables, (data) => { });
      }
    }

    if (
      !window.cordova &&
      (isBrowser ||
        isChrome ||
        isFirefox ||
        isSafari ||
        isOpera ||
        isIE ||
        isEdge ||
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        ))
    ) {
      if (isChrome) {
      }
      if (isFirefox) {
      }
      if (isSafari) {
      }
      if (isOpera) {
      }
      if (isIE) {
      }
      if (isEdge) {
      }
      if (
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        )
      ) {
      }
      //check is app only and no web
      if (type == "open") {
          console.log("HERE IS RESOURCETYPE: ", data.ResourceType)
          console.log("HERE IS FILESOURCE: ", data.FileSource)
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "OTHER":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(data.ResourceId, {
                actionType: "open",
                restype: data.ResourceType,
                platform: "Web",
              });
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
          case "MASTERWEBITE":
            return HandleSSOtoOtherPlatform("WEB", {
              platform: "web",
              url: data.ResourceAccessUrl,
            });
           break;
          case "EBOOK4":
            window.open(data.ResourceAccessUrl, "_blank");
            break;
          case "IMAGE":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                (path) => {
                  //imagebox

                                    setImageLightBoxOpen({
                                        id: data.ResourceId,
                                        image: path,
                                        obj: data,
                                    });
                                    setfetchingEPSResourceToken(false);
                                }
                            );
                        } else {
                            window.open(data.ResourceAccessUrl, "_blank");
                        }
                        break;

                    case "YOUTUBE":
                        handleVideoBox({...data, type: "youtube", obj: data});
                        break;
                    case "VIMEO":
                        handleVideoBox({...data, type: "vimeo", obj: data});
                        break;
                    case "LEARNOSITY":
                        var sarg = props.match.params.id;

                        props.history.push({
                            pathname:
                                "/a/studygroup/" +
                                sarg +
                                "/assessment/" +
                                "qs&" +
                                selectedBookId +
                                "/" +
                                data.ResourceId +
                                "/start",
                            state: {
                                backURL: "SDL",
                                previewURL: "",
                                group_id: tabClassId,
                                leftMenu: "",
                                bookVal: selectedBookId,
                            },
                        });
                        dispatch({
                            type: "SET_SELECTED_TYPE",
                            selectOptions: {
                                selectedStudyGroupId: tabClassId,
                                selectedStudyGroupName: selectedClass,
                                selectecResourceTab: activeBook,
                                selectedToggle: toggleProgress,
                                seletctedTabSDL: "Resource",
                                selectedResourceName: activeBookName,
                                setselectedResAssignId: "",
                                selectedReportType: "",
                                selectedAssessType: "",
                                firstLevel: "", //activeFetchId,
                                secondLevel: "", //bredCrumRes[1],
                                thirdLevel: "", //bredCrumRes[2],
                                backpathRes:"SDL"
                            },
                        });
                        // var newURL = window.location.pathname.replace(
                        //   props.match.url,
                        //   "/a/studygroup/" +
                        //     sarg +
                        //     "/assessment/" +
                        //     "qs" +
                        //     "/" +
                        //     data.ResourceId +
                        //     "/start"
                        // );
                        // window.open(newURL, "_blank");

                        break;
                    case "PRIZM":
                        var sarg = props.match.params.id;
                        // var newURL = window.location.pathname.replace(
                        //   props.match.url,
                        //   "/a/studygroup/" +
                        //     sarg +
                        //     "/assessment/" +
                        //     "prizm" +
                        //     "/" +
                        //     data.ResourceId +
                        //     "/start"
                        // );
                        // props.history.push({
                        //   pathname: newURL,
                        //   state: {
                        //     group_id: tabClassId,
                        //     // leftMenu: bredCrumRes.join(" > "),
                        //   },
                        // });
                        props.history.push({
                            pathname:
                                "/a/studygroup/" +
                                sarg +
                                "/assessment/" +
                                "prizm&" +
                                selectedBookId +
                                "/" +
                                data.ResourceId +
                                "/start",
                            state: {
                                backURL: "SDL",
                                previewURL: "",
                                group_id: tabClassId,
                                leftMenu: "",
                                bookVal: selectedBookId,
                            },
                        });
                        dispatch({
                            type: "SET_SELECTED_TYPE",
                            selectOptions: {
                                selectedStudyGroupId: tabClassId,
                                selectedStudyGroupName: selectedClass,
                                selectecResourceTab: activeBook,
                                seletctedTabSDL: "Resource",
                                selectedToggle: toggleProgress,
                                selectedResourceName: activeBookName,
                                setselectedResAssignId: "",
                                selectedReportType: "",
                                selectedAssessType: "",
                                firstLevel: "", //activeFetchId,
                                secondLevel: "", //bredCrumRes[1],
                                thirdLevel: "", //bredCrumRes[2],
                                backpathRes:"SDL"
                            },
                        });
                        // props.history.push(
                        //   "/a/studygroup/" +
                        //     sarg +
                        //     "/assessment/" +
                        //     "prizm" +
                        //     "/" +
                        //     data.ResourceId +
                        //     "/start"
                        // );
                        //window.open(newURL, "_blank");
                        break;
                    default:
                        if (data.FileSource == "eps") {
                            // eps download or open
                            getEPSToken(data.ResourceId, {
                                actionType: "open",
                                restype: data.ResourceType,
                                platform: "Web",
                            });
                        } else {
                            window.open(data.ResourceAccessUrl, "_blank");
                        }
                        break;
                }
            }

            if (type == "download") {
                switch (data.ResourceType) {
                    case "WEB_LINK":
                    case "VIDEO":
                    case "DOCUMENT":
                    case "AUDIO":
                    case "EBOOK4":
                    case "LEARNOSITY":
                    case "PRIZM":
                    case "OTHER":
                    case "IMAGE":
                    default:
                        if (data.FileSource == "eps") {
                            // eps download or open
                            getEPSToken(data.ResourceId, {
                                actionType: "download",
                                restype: data.ResourceType,
                                platform: "Web",
                            });
                        } else {
                            var a = document.createElement("a");
                            a.href = data.ResourceAccessUrl;
                            a.download = true;
                            a.target = "_blank";
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove(); //afterwards we remove the element again
                        }
                        break;
                }
            }
            //only web
        }
        if (window.cordova) {
            if (type === "open") {
                switch (data.ResourceType) {
                    case "WEB_LINK":
                    case "VIDEO":
                    case "DOCUMENT":
                    case "AUDIO":
                    case "OTHER":
                        if (data.FileSource === "eps") {
                            // eps download or open
                            getEPSToken(data.ResourceId, {
                                actionType: "open",
                                restype: data.ResourceType,
                                platform: "Web",
                            });
                        } else {
                            systemBrowserOpenLink(data.ResourceAccessUrl);
                        }
                        break;
                    case "EBOOK4":
                        systemBrowserOpenLink(data.ResourceAccessUrl);
                        break;
                    case "IMAGE":
                        if (data.FileSource == "eps") {
                            // eps download or open
                            getEPSToken(
                                data.ResourceId,
                                {
                                    actionType: "open",
                                    restype: data.ResourceType,
                                    platform: "Web",
                                },
                                (path) => {
                                    //imagebox

                                    setImageLightBoxOpen({
                                        id: data.ResourceId,
                                        image: path,
                                        obj: data,
                                    });
                                    setfetchingEPSResourceToken(false);
                                }
                            );
                        } else {
                            systemBrowserOpenLink(data.ResourceAccessUrl);
                        }
                        break;

                    case "YOUTUBE":
                        handleVideoBox({...data, type: "youtube", obj: data});
                        break;
                    case "VIMEO":
                        handleVideoBox({...data, type: "vimeo", obj: data});
                        break;
                    case "LEARNOSITY":
                        if (isIOS) {
                            var sarg = props.match.params.id;
                            const pathname = "/a/studygroup/" +
                                sarg +
                                "/assessment/" +
                                "qs&" +
                                selectedBookId +
                                "/" +
                                data.ResourceId +
                                "/start"
                            openLearnosity(window.location.hostname, pathname);
                        } else {
                            var sarg = props.match.params.id;

                            props.history.push({
                                pathname:
                                    "/a/studygroup/" +
                                    sarg +
                                    "/assessment/" +
                                    "qs&" +
                                    selectedBookId +
                                    "/" +
                                    data.ResourceId +
                                    "/start",
                                state: {
                                    backURL: "SDL",
                                    previewURL: "",
                                    group_id: tabClassId,
                                    leftMenu: "",
                                    bookVal: selectedBookId,
                                },
                            });
                            dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {
                                    selectedStudyGroupId: tabClassId,
                                    selectedStudyGroupName: selectedClass,
                                    selectecResourceTab: activeBook,
                                    selectedToggle: toggleProgress,
                                    seletctedTabSDL: "Resource",
                                    selectedResourceName: activeBookName,
                                    setselectedResAssignId: "",
                                    selectedReportType: "",
                                    selectedAssessType: "",
                                    firstLevel: "", //activeFetchId,
                                    secondLevel: "", //bredCrumRes[1],
                                    thirdLevel: "", //bredCrumRes[2],
                                    backpathRes:"SDL"
                                },
                            });
                        }
                        break;
                    case "PRIZM":
                        console.log("isIOS? ", isIOS);
                        if (isIOS) {
                            console.log("HERE IS data:", data)
                            dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {
                                    selectedStudyGroupId: tabClassId,
                                    selectedStudyGroupName: selectedClass,
                                    selectecResourceTab: activeBook,
                                    seletctedTabSDL: "Resource",
                                    selectedToggle: toggleProgress,
                                    selectedResourceName: activeBookName,
                                    setselectedResAssignId: "",
                                    selectedReportType: "",
                                    selectedAssessType: "",
                                    firstLevel: "", //activeFetchId,
                                    secondLevel: "", //bredCrumRes[1],
                                    thirdLevel: "", //bredCrumRes[2],
                                    backpathRes:"SDL"
                                },
                            });
                            iosOpenPrizm(props, data.ResourceLangs[0]?.ResourceName, data.ResourceId, tabClassId,  selectedSchoolYear.SchoolYearId, encrypt(userCredential), data);
                            return;
                        }
                        var sarg = props.match.params.id;
                        props.history.push({
                            pathname:
                                "/a/studygroup/" +
                                sarg +
                                "/assessment/" +
                                "prizm&" +
                                selectedBookId +
                                "/" +
                                data.ResourceId +
                                "/start",
                            state: {
                                backURL: "SDL",
                                previewURL: "",
                                group_id: tabClassId,
                                leftMenu: "",
                                bookVal: selectedBookId,
                            },
                        });
                        dispatch({
                            type: "SET_SELECTED_TYPE",
                            selectOptions: {
                                selectedStudyGroupId: tabClassId,
                                selectedStudyGroupName: selectedClass,
                                selectecResourceTab: activeBook,
                                seletctedTabSDL: "Resource",
                                selectedToggle: toggleProgress,
                                selectedResourceName: activeBookName,
                                setselectedResAssignId: "",
                                selectedReportType: "",
                                selectedAssessType: "",
                                firstLevel: "", //activeFetchId,
                                secondLevel: "", //bredCrumRes[1],
                                thirdLevel: "", //bredCrumRes[2],
                                backpathRes:"SDL"
                            },
                        });
                        break;
                    case "INTERACTIVE":
                        const cookie = data.ResourceAccessUrl.split("/index.html?")[1];
                        const cookieKey = cookie.split('=')[0];
                        const browserRef = window.cordova.InAppBrowser.open(data.ResourceAccessUrl, "_blank", "hidden=yes");
                        browserRef.addEventListener("loadstop", function() {
                            browserRef.executeScript({
                                code: `if (!document.cookie || document.cookie.indexOf('${cookieKey}') === -1) {
                                            document.cookie = '${cookie}';
                                            location.reload();
                                        } else {
                                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "showIAB"}));
                                        }`
                            })
                        })

                        browserRef.on('message', function(params) {
                            if (params.data.action === "showIAB") {
                                browserRef.show();
                            }
                        })

                        break;
                    case "MASTERWEBITE":
                        return HandleSSOtoOtherPlatform(isIOS ? "IOS" : "ANDROID", {
                            platform: "web",
                            url: data.ResourceAccessUrl,
                        });
                    default:
                        if (data.FileSource == "eps") {
                            // eps download or open
                            getEPSToken(data.ResourceId, {
                                actionType: "open",
                                restype: data.ResourceType,
                                platform: "Web",
                            });
                        } else {
                            systemBrowserOpenLink(data.ResourceAccessUrl);
                        }
                        break;
                }
            }
        }
    };

    const getPreviewURL = (data) => {
        var listArrayTemp = [...data];
        var listArray = listArrayTemp.filter((data) => {
            return data.LastAttempt == "Y";
        });
        return listArray[0].LearnositySessionResult;
    };
    const CountScore = (data) => {
        if (UserType == "S") {
            var listArrayTemp = [...data];
            // listArray.sort(function (a, b) {
            //   return new moment(a.AttemptDate) - new moment(b.AttemptDate);
            // });
            //listArray.reverse();
            var listArray = listArrayTemp.filter((data) => {
                return data.LastAttempt == "Y";
            });

            if (!listArray[0].Score || !listArray[0].MaxScore) return "-";
            var score =
                (parseFloat(listArray[0].Score) / parseInt(listArray[0].MaxScore)) *
                100;
            //var score = score +  parseFloat(((data[0].Score / data[0].MaxScore) * 100).toFixed(0));
            return Math.round(score);
        }
        if (UserType == "T") {
            var score = 0;
            var maxScore = 0;
            var percentageT = 0;
            data.map((obj, index) => {
                if (!obj.Score || !obj.MaxScore) return;
                //score = score + (parseInt(obj.Score) / parseInt(obj.MaxScore)) * 100;
                //score = score +  parseFloat(((obj.Score / obj.MaxScore) * 100).toFixed(0));
                //score = score + (obj.Score / obj.MaxScore) * 100;
                let temp = parseFloat(obj.Score);

                if (maxScore == 0) {
                    maxScore = parseFloat(obj.MaxScore).toFixed(0);
                }

                var percentageTemp = Math.round((temp / maxScore) * 100);
                percentageT = percentageTemp + percentageT;
                // score = score + temp;
            });

            score = (score / data.length / maxScore) * 100;
            //score = score / data.length;
            return Math.round(percentageT / data.length);
        }

        return 0;
    };

    const showScore = (obj) => {
        var avgScore = 0;
        if (UserType == "T") {
            if (
                obj.SelfLearnLastAttemptHistoryByStudyGroup &&
                obj.SelfLearnLastAttemptHistoryByStudyGroup.length > 0
            ) {
                //console
                avgScore = CountScore(obj.SelfLearnLastAttemptHistoryByStudyGroup);
            } else {
                return (
                    <span className={`main__section-unit-grid-details-progress`}>
            <i className="main__section-unit-grid-details-progress-bar">-</i>
          </span>
                );
            }
        }
        if (UserType == "S") {
            if (
                obj.SelfLearnAttemptHistory &&
                obj.SelfLearnAttemptHistory.length > 0
            ) {
                avgScore = CountScore(obj.SelfLearnAttemptHistory);
            } else {
                return (
                    <span className={`main__section-unit-grid-details-progress`}>
            <i className="main__section-unit-grid-details-progress-bar">-</i>
          </span>
                );
            }
        }
        var progressLevelClass = "";
        if (avgScore <= 20) {
            progressLevelClass = "progressbar-lowest";
        } else if (avgScore > 20 && avgScore <= 40) {
            progressLevelClass = "progressbar-low";
        } else if (avgScore > 40 && avgScore <= 60) {
            progressLevelClass = "progressbar-medium";
        } else if (avgScore > 60 && avgScore <= 80) {
            progressLevelClass = "progressbar-high";
        } else if (avgScore > 80) {
            progressLevelClass = "progressbar-highest";
        } else {
            progressLevelClass = "progressbar-lowest";
        }

        return (
            <div
                className={`progressbar ${progressLevelClass}`}
                role="progressbar"
                aria-valuenow={avgScore}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{"--value": avgScore}}
            ></div>
        );
    };

    const mapConfig = (t) => {
        switch (t) {
            case "hide":
                return "HideResource";
            case "show":
                return "ShowResource";
        }
    };

    const handleResourceData = (type, objArg, showhideBool) => {
        //setloadingList(true);
        setHideShowLoading(true);
        const lang = localStorage.getItem("i18nextLng");
        var tempConfig = [];
        tempConfig.push({
            ResourceId: objArg.ResourceId,
            StudyGroupId: studyGroupData.StudyGroupId,
            config: objArg.VisibleByStudent == "N" ? "HideResource" : "ShowResource",
            StudyGroupName:
            studyGroupData.StudyGroupName[
                studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1
                ].StudyGroupName,
            type: type,
        });
        var newConfig = [];
        var newStudyGroup = tempConfig;
        //setstudyGroupList(tempConfig);
        const ids = tempConfig.map((eobj) => eobj.StudyGroupId);
        // fetch data and restructure
        var variables = {
            ResourceId: [objArg.ResourceId],
            StudyGroupId: ids,
            SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
                .SchoolYearId,
        };
        UnitListGetSelfLearnConfig(variables).then((data) => {
            const {
                resourceSearch: {Resources = [{}]},
            } = data || {};
            Resources.map((obj) => {
                if (
                    obj.SelfLearnConfiguration.map((i) => {
                        return i.ConfigName;
                    }).indexOf("ShowHide") !== -1
                ) {
                    const filterd = obj.SelfLearnConfiguration.filter((lobj) => {
                        return lobj.ConfigName == "ShowHide";
                    });

                    const resId = obj.SelfLearnConfiguration[0].ResourceId;

                    newStudyGroup.map((gobj) => {
                        if (
                            filterd
                                .map((i) => {
                                    return i.StudyGroupId;
                                })
                                .indexOf(gobj.StudyGroupId) !== -1
                        ) {
                            newConfig.push({
                                ResourceId: resId,
                                StudyGroupId: gobj.StudyGroupId,
                                config: mapConfig(
                                    filterd[
                                        filterd
                                            .map((i) => {
                                                return i.StudyGroupId;
                                            })
                                            .indexOf(gobj.StudyGroupId)
                                        ].ConfigValue
                                ),
                                StudyGroupName: gobj.StudyGroupName,
                                type: type,
                            });
                        } else {
                            newConfig.push({
                                ResourceId: resId,
                                StudyGroupId: gobj.StudyGroupId,
                                config:
                                    objArg.VisibleByStudent == "N"
                                        ? "HideResource"
                                        : "ShowResource",
                                StudyGroupName: gobj.StudyGroupName,
                                type: type,
                            });
                        }
                    });
                    //setstudyGroupList(newConfig);
                    handleToggleSwitch(showhideBool, newConfig);
                    //setloadingList(false);
                } else {
                    const addConfig = newStudyGroup.map((cobj) => ({
                        ...cobj,
                        config:
                            objArg.VisibleByStudent == "N" ? "HideResource" : "ShowResource",
                        ResourceId: objArg.ResourceId,
                        type: type,
                    }));

                    //setstudyGroupList(addConfig);
                    handleToggleSwitch(showhideBool, addConfig);
                    //setloadingList(false);
                }
            });
        });

        // const resName =
        //   objArg.ResourceLangs[objArg.ResourceLangs[0].Lang == lang ? 0 : 1]
        //     .ResourceName;
        // setactionmodalData({
        //   type: type,
        //   isOpen: true,
        //   title: {
        //     header: t("Resource to show to students"),
        //     resName: resName,
        //     subtitle: "",
        //   },
        // });
    };

    const handleToggleSwitch = (val, studyGroupArg) => {
        var final = [];

        studyGroupArg.map((obj, i) => {
            if (obj.StudyGroupId == ClassId) {
                if (val) {
                    if (obj.type == "view") {
                        return (studyGroupArg[i].config = "ShowResource");
                    }
                } else {
                    if (obj.type == "view") {
                        return (studyGroupArg[i].config = "HideResource");
                    }
                }
            }
        });

        studyGroupArg.map((objz) => {
            final.push({
                ResourceId: objz.ResourceId,
                SchoolYearId: JSON.parse(localStorage.getItem("selectedSchoolYear"))
                    .SchoolYearId,
                StudyGroupId: objz.StudyGroupId,
                Action: objz.config,
                StructureId: openedUnitSection,
            });
        });
        setfinal(final);
    };

    const checkViewPermission = (obj, type) => {
        // var viewFlag = true;
        return true;
        if (UserType === "T") {
            return true;
        } else {
            if (obj.VisibleByStudent == "N") {
                return false;
            } else {
                return true;
            }
        }
        // return viewFlag;
    };

    const checkViewandRedo = (obj, type, el) => {
        // const configLength = obj.SelfLearnConfiguration.length;
        const resName =
            obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1].ResourceName;

        if (obj.VisibleByStudent == "N" && obj.ShareableToStudent == "N") {
            return <i className="not-viewable-icon-disabled"></i>;
        } else if (
            obj.SelfLearnConfiguration.map((i) => {
                return i.ConfigName;
            }).indexOf("ShowHide") == -1
        ) {
            return (
                <>
                    <div className="assigned_students_separate">
                        {obj.VisibleByStudent == "Y" && obj.ShareableToStudent == "Y" && (
                            <i
                                className="viewable-icon"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Hide'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Hide'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, false);
                                    confirmDataClickable(obj, el, true, true);
                                    // setIsViewable(true);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}

                        {obj.VisibleByStudent == "Y" && obj.ShareableToStudent == "N" && (
                            <i
                                className="not-viewable-icon"
                                // onClick={() => {
                                //   handleResourceData("view", obj, false);
                                //   setIsViewable(true);
                                //   setShowPopUp(true);
                                //   setActiveSubUnitSectionName(
                                //     obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                //       .ResourceName
                                //   );
                                // }}
                            ></i>
                        )}

                        {obj.VisibleByStudent == "N" && obj.ShareableToStudent == "Y" && (
                            <i
                                className="viewable-icon-disabled"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Show'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Show'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, true);
                                    confirmDataClickable(obj, el, false, true);
                                    // setIsViewable(false);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}
                    </div>
                </>
            );
        } else if (
            obj.SelfLearnConfiguration.map((i) => {
                return i.ConfigName;
            }).indexOf("ShowHide") !== -1
        ) {
            const configValueView =
                obj.SelfLearnConfiguration[
                    obj.SelfLearnConfiguration.map((i) => {
                        return i.ConfigName;
                    }).indexOf("ShowHide")
                    ].ConfigValue;
            return (
                <div className="assigned_students_separate">
                    {obj.VisibleByStudent == "Y" &&
                        obj.ShareableToStudent == "Y" &&
                        configValueView == "show" && (
                            <i
                                className="viewable-icon"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Hide'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Hide'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, false);
                                    confirmDataClickable(obj, el, true, true);
                                    // setIsViewable(true);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}

                    {obj.VisibleByStudent == "Y" &&
                        obj.ShareableToStudent == "Y" &&
                        configValueView == "hide" && (
                            <i
                                className="viewable-icon-disabled"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Show'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Show'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, true);
                                    confirmDataClickable(obj, el, false, true);
                                    // setIsViewable(false);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}
                    {obj.VisibleByStudent == "Y" &&
                        obj.ShareableToStudent == "N" &&
                        configValueView == "show" && (
                            <i
                                className="not-viewable-icon"
                                // onClick={() => {
                                //   handleResourceData("view", obj, false);
                                //   setIsViewable(true);
                                //   setShowPopUp(true);
                                //   setActiveSubUnitSectionName(
                                //     obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                //       .ResourceName
                                //   );
                                // }}
                            ></i>
                        )}

                    {obj.VisibleByStudent == "Y" &&
                        obj.ShareableToStudent == "N" &&
                        configValueView == "hide" && (
                            <i
                                className="not-viewable-icon"
                                // onClick={() => {
                                //   handleResourceData("view", obj, true);
                                //   setIsViewable(false);
                                //   setShowPopUp(true);
                                //   setActiveSubUnitSectionName(
                                //     obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                //       .ResourceName
                                //   );
                                // }}
                            ></i>
                        )}

                    {obj.VisibleByStudent == "N" &&
                        obj.ShareableToStudent == "Y" &&
                        configValueView == "show" && (
                            <i
                                className="viewable-icon"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Hide'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Hide'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, false);
                                    confirmDataClickable(obj, el, true, true);
                                    // setIsViewable(true);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}

                    {obj.VisibleByStudent == "N" &&
                        obj.ShareableToStudent == "Y" &&
                        configValueView == "hide" && (
                            <i
                                className="viewable-icon-disabled"
                                onClick={() => {
                                    if (!window.cordova) {
                                        window.gtag('event', 'SDL - Open show hide setting for resource', {
                                            'Resource':obj.ResourceLangs[
                                                obj.ResourceLangs[0].Lang == lang
                                                    ? 0
                                                    : 1
                                                ].ResourceName,
                                            'Resource_ID': obj.IngestionId,
                                            'Resource_Type': obj.ResourceType,
                                            'Book_Series': props.selectedBookSeriesName,
                                            'Book': props.selectedBookName,
                                            'Unit': activeBookName,
                                            'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                            'user_Role': UserType,
                                            'Action': 'Show'
                                            })
                                    } else {
                                        // sendGaEvent('SDL - Open show hide setting for resource', {
                                        //     'Resource':obj.ResourceLangs[
                                        //         obj.ResourceLangs[0].Lang == lang
                                        //             ? 0
                                        //             : 1
                                        //         ].ResourceName,
                                        //     'Resource_ID': obj.IngestionId,
                                        //     'Resource_Type': obj.ResourceType,
                                        //     'Book_Series': props.selectedBookSeriesName,
                                        //     'Book': props.selectedBookName,
                                        //     'Unit': activeBookName,
                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                        //     'user_Role': UserType,
                                        //     'Action': 'Show'
                                        // })
                                    }
                                    setResourceDetails(obj)
                                    handleResourceData("view", obj, true);
                                    confirmDataClickable(obj, el, false, true);
                                    // setIsViewable(false);
                                    // setShowPopUp(true);
                                    setActiveSubUnitSectionName(
                                        obj.ResourceLangs[obj.ResourceLangs[0].Lang == lang ? 0 : 1]
                                            .ResourceName
                                    );
                                }}
                            ></i>
                        )}
                </div>
            );
        }
    };

    const getSubmit = (obj) => {
        if (obj.ResourceType == "PRIZM" || obj.ResourceType == "LEARNOSITY") {
            if (UserType == "T") {
                return obj.SelfLearnLastAttemptHistoryByStudyGroup.length > 0
                    ? obj.SelfLearnLastAttemptHistoryByStudyGroup.length
                    : "-";
            } else {
                return obj.SelfLearnAttemptHistory.length > 0
                    ? obj.SelfLearnAttemptHistory.length
                    : "-";
            }
        }
    };


    const fetchResources = (activeFetchId, callApi = 0) => {
        // console.log('inside class room - fetchResources')
        var allData = [...subUnitListing];
        var latestData = subUnitListing.findIndex(
            (x) => x.structureId == activeFetchId
        );
        setUnitItemLoading(true);
        if (allData[latestData]?.details.length == 0 || callApi == 1) {
            //setresourceData([]);
            // setresourceDataLoading(true);
            var variables = {
                StudyGroupId: ClassId || "",
                StructureId: activeFetchId,
                SchoolYearId: selectedSchoolYear.SchoolYearId,
                PageNo: currentPage,
                UserId: JSON.parse(localStorage.getItem("userData")).UserId,
                PageSize: 20,
                StudyGroupIds: ClassId == null ? [] : [ClassId],
                Credential: window.cordova ? encrypt(userCredential) : null
            };

            fetchQuery(
                environment,
                UNIT_PAGE_STRUCTURE_RESOURCES_LIST_QUERY,
                variables
            ).then((data) => {
                if (!data?.resourceCenterStructure) {
                    return props.history.push("/m/myschool");
                }
                if (!data.resourceCenterStructure.RelatedResources) {
                    return props.history.push("/m/myschool");
                }

                // var userType = JSON.parse(localStorage.getItem("userData")).UserType;
                var predata = [];
                // if (userType == "T") {
                if (data.resourceCenterStructure.RelatedResources.Resources != null)
                    predata = [
                        ...data.resourceCenterStructure.RelatedResources.Resources,
                    ];

                var allData = [...subUnitListing];
                var latestData = subUnitListing.findIndex(
                    (x) => x.structureId == activeFetchId
                );
                if (allData[latestData].details.length == 0 || callApi == 1) {
                    var summaryInfoSub = JSON.parse(
                        data.resourceCenterStructure.SummaryData
                    );
                    var progressClassVal = "";
                    var progressClassName = "large-progressbar-highest";
                    if (summaryInfoSub.avgScore === null) {
                        progressClassVal = "";
                    } else {
                        progressClassVal = Math.round(summaryInfoSub.avgScore);
                    }
                    allData[latestData].avgScore = progressClassVal;
                    allData[latestData].details = [...predata];
                    allData[latestData].submittedAvg = "-";
                    allData[latestData].readAvg = "-";
                    var totalResCount = 0;
                    var totalViewResCount = 0;
                    var submittedCount = 0;
                    var viewCount = 0;
                    predata.map((objRes, index) => {
                        if (
                            objRes.ResourceType == "PRIZM" ||
                            objRes.ResourceType == "LEARNOSITY"
                        ) {
                            totalResCount = totalResCount + 1;

                            if (UserType == "T") {
                                var submitCountTemp =
                                    objRes.SelfLearnLastAttemptHistoryByStudyGroup.length > 0
                                        ? objRes.SelfLearnLastAttemptHistoryByStudyGroup.length
                                        : 0;
                            } else {
                                var submitCountTemp =
                                    objRes.SelfLearnAttemptHistory.length > 0
                                        ? objRes.SelfLearnAttemptHistory.length
                                        : 0;
                            }

                            submittedCount = submittedCount + submitCountTemp;
                        } else {
                            totalViewResCount = totalViewResCount + 1;
                            var submitViewTemp = objRes.ResourceReadStatus
                                ? objRes.ResourceReadStatus.length
                                : 0;
                            viewCount = viewCount + submitViewTemp;
                        }
                    });
                }

                if (totalResCount > 0 && submittedCount > 0) {
                    //allData[latestData].submittedAvg = Math.round((submittedCount/totalResCount)*100)
                    allData[latestData].submittedAvg = submittedCount;
                }
                if (totalViewResCount > 0 && viewCount > 0) {
                    //allData[latestData].readAvg = Math.round((viewCount/totalViewResCount)*100)
                    allData[latestData].readAvg = viewCount;
                }
                setUnitItemLoading(false);
                setsubUnitListing(allData);
                // setresourceDataLoading(false);
                // setresourceData(predata);
                // setresourceCenterStructure(data.resourceCenterStructure);
            });
        } else {
            // setTimeout(() => {
            setUnitItemLoading(false);
            setsubUnitListing(allData);
            // }, 1200);
        }
    };

    const doOpenPrizmResult = (e, obj) => {
        e.stopPropagation();
        let path = null;

        props.history.push({
            pathname:
                "/a/studygroup/" +
                props.match.params.id +
                "/assessment/" +
                "prizm&" +
                selectedBookId +
                "/" +
                obj.ResourceId +
                "/result",
            state: {
                backURL: "",
                group_id: tabClassId,
                leftMenu: "",
                bookVal: selectedBookId,
            },
        });
        dispatch({
            type: "SET_SELECTED_TYPE",
            selectOptions: {
                selectedStudyGroupId: tabClassId,
                selectedStudyGroupName: selectedClass,
                selectedResourceName: activeBookName,
                selectecResourceTab: activeBook,
                setselectedResAssignId: "",
                selectedToggle: toggleProgress,
                selectedReportType: "",
                selectedAssessType: "",
                firstLevel: "", //activeFetchId,
                secondLevel: "", //bredCrumRes[1],
                thirdLevel: "", //bredCrumRes[2],
                backpathRes: "SDL"
            },
        });
    };

    const showAttempHistory = (attempObj) => {
        if(attempObj.ResourceReadStatus.length>0){
            setattemptStudentInfo(attempObj.ResourceReadStatus)
            setresourceNamePop(attempObj.ResourceLangs[
                attempObj.ResourceLangs[0].Lang == lang
                    ? 0
                    : 1
                ].ResourceName)
            setShowAttempt(true)
            // setShowPageLoading(true)

            // setTimeout(() => {
            //     setShowPageLoading(false)
            //     setShowAttempt(true)
            // }, 3000);
        }
    }
    return unitLoadingHere ? (
        <Loading type={`normal`}/>
    ) : (
        <>
            {hideShowLoading && <Loading/>}
            {showPageLoading && <Loading/>}
            {showPopUp && (
                <ViewNotificationPopUp
                    activeSubUnitSectionName={activeSubUnitSectionName}
                    showPopUp={showPopUp}
                    setShowPopUp={setShowPopUp}
                    submitShowHide={submitShowHide}
                    isViewable={isViewable}
                    t={props.t}
                    selectedClass={selectedClass}
                />
            )}
            {showAttempt && (
                <ViewStudentAttemptHistoryPopUp
                    t={props.t}
                    showAttempt={showAttempt}
                    classListId={ClassId}
                    selectedClass={selectedClass}
                    attemptStudentInfo={attemptStudentInfo}
                    setShowAttempt={setShowAttempt}
                    resourceNamePop={resourceNamePop}
                />
            )}
            {showNotificationPopUp && (
                <ViewNotificationAlreadySetPopUp
                    t={props.t}
                    showNotificationPopUp={showNotificationPopUp}
                    setShowNotificationPopUp={setShowNotificationPopUp}
                />
            )}

            {!showSDLPage ? (
                <>
                    <div className="main__section-unit">
                        <VidoeLightBox
                            handleVideoBox={handleVideoBox}
                            VideoLightBoxOpenState={videoLightBoxOpen}
                        />

                        <ImageLightBox
                            isOpen={imageLightBoxOpen ? true : false}
                            handleImageBox={handleImageBox}
                            ImageLightBoxOpenState={imageLightBoxOpen}
                            downloadImage={() =>
                                checkDeviceToOpenOrDownload(imageLightBoxOpen.obj, "download")
                            }
                        />
                        {resourceCenterStructureList.map((obj, index) => {
                            console.log("test")
                            return (
                                <>
                                    {obj.book.options.map((oobj, oindex) => {
                                        var summaryInfo = JSON.parse(oobj.SummaryData);
                                        var progressClassVal = "";
                                        // var progressClassName = "";
                                        if (summaryInfo.avgScore === null) {
                                        } else {
                                            progressClassVal = Math.round(summaryInfo.avgScore);
                                        }

                                        var progressClassName = getScoreAndAverageClass(
                                            progressClassVal,
                                            "largescroll"
                                        );

                                        console.log(`
                                        summaryInfo.avgScore = ${summaryInfo.avgScore}
                                        progressClassName = ${progressClassName}
                                        progressClassVal = ${progressClassVal}
                                        `)

                                        return (
                                            <React.Fragment key={oindex}>
                                                <div
                                                    className={`main__section-unit-section ${activeBook == oobj.StructureId ? "active" : ""
                                                    }`}
                                                    onClick={() => {
                                                        // setUnitLoading(true);
                                                        setUnitSubLoading(true);
                                                        setActiveBook(oobj.StructureId);
                                                        setOpenedUnitSection(oobj.StructureId);
                                                        setmainListRefresh(mainListRefresh + 1);
                                                        setActiveBookName(
                                                            oobj.StructureLang[
                                                                oobj.StructureLang[0].Lang == lang ? 0 : 1
                                                                ].StructureTitle
                                                        );
                                                        retainChapterFunc(
                                                            oobj.StructureId,
                                                            oobj.StructureLang[
                                                                oobj.StructureLang[0].Lang == lang ? 0 : 1
                                                                ].StructureTitle
                                                        );
                                                        updateSubUnitListing(oobj.StructureId);
                                                        setUnitSubLoading(false);
                                                        // setTimeout(() => {
                                                        //   setUnitLoading(false);
                                                        // }, 1300);
                                                    }}
                                                >
                                                    <h4 className="main__section-unit-heading">
                                                        {
                                                            oobj.StructureLang[
                                                                oobj.StructureLang[0].Lang == lang ? 0 : 1
                                                                ].StructureTitle
                                                        }
                                                    </h4>
                                                    <div className="main__section-unit-details">
                            <span className="main__section-unit-details-label">
                              {props.t("Avg. Score")}
                            </span>
                                                        {!toggleProgress || progressClassName == "" ? (
                                                            <span
                                                                className={`main__section-unit-details-progress ${activeBook == oobj.StructureId ? "active" : ""
                                                                }`}
                                                            >
                                <i className="main__section-unit-details-progress-bar">
                                  -
                                </i>
                              </span>
                                                        ) : (
                                                            <div
                                                                className={`large-progressbar ${progressClassName}`}
                                                                role="large-progressbar"
                                                                aria-valuenow={progressClassVal}
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                                style={{"--value": progressClassVal}}
                                                            ></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </div>
                    {unitSubLoading ? (
                        <Loading type={`normal`}/>
                    ) : (
                        <div className="main__section-unit-grid">
                            <h3 className="main__section-unit-grid-heading">
                                {activeBookName}
                            </h3>
                            {/* <h3 className="main__section-unit-grid-heading">Unit 1</h3> */}
                            <div className="main__section-table-details">
                                <table className="main__section-unit-grid-table">
                                    <thead>
                                    <tr>
                                        <th className="main__section-unit-grid-table-head main__section-unit-grid-table-head1 head-item">
                                            {t("Item")}
                                        </th>
                                        {UserType == "S" && <th className="main__section-unit-grid-table-head head-average">
                                            {t("Viewed")}
                                        </th>}
                                        <th className="main__section-unit-grid-table-head head-average">
                                            {t("Avg. Score")}
                                        </th>
                                        {UserType == "T" ? (
                                            <>
                                                <th className="main__section-unit-grid-table-head head-submitted">
                                                    {/* {t("Student Submitted")} */}
                                                    {t("SubmittedSDL")}
                                                </th>
                                                <th className="main__section-unit-grid-table-head head-viewed">
                                                    {/* {t("Student Viewed")} */}
                                                    {t("Viewed")}
                                                </th>
                                                <th className="main__section-unit-grid-table-head head-viewable">
                                                    {/* {t("Viewable")} */}
                                                    {t("Share")}
                                                </th>
                                                <th className="main__section-unit-grid-table-head head-google-classroom">
                                                    {t("Google Classroom")}
                                                </th>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <th className="main__section-unit-grid-table-head head-result">
                                            {t("Results")}
                                        </th>
                                    </tr>
                                    </thead>
                                </table>
                                {subUnitListing.length>0 ? subUnitListing.map((el) => {
                                    // console.log('inside class room1 - subUnitListing map functions', el.structureId)
                                    var mainProgressLevelClass = "";
                                    if (el.avgScore === "") {
                                    } else {
                                        var scoreTemp = Math.round(el.avgScore);
                                        if (scoreTemp <= 20) {
                                            mainProgressLevelClass = "progressbar-lowest";
                                        } else if (scoreTemp > 20 && scoreTemp <= 40) {
                                            mainProgressLevelClass = "progressbar-low";
                                        } else if (scoreTemp > 40 && scoreTemp <= 60) {
                                            mainProgressLevelClass = "progressbar-medium";
                                        } else if (scoreTemp > 60 && scoreTemp <= 80) {
                                            mainProgressLevelClass = "progressbar-high";
                                        } else if (scoreTemp > 80) {
                                            mainProgressLevelClass = "progressbar-highest";
                                        } else {
                                        }
                                    }
                                    console.log('qwerty-fgfdgd-outside', openedUnitSection, el.structureId, openedUnitSectionFlag)

                                    return (
                                        <details
                                            className="main__section-unit-grid-table-details"
                                            open={
                                                openedUnitSectionFlag &&
                                                openedUnitSection == el.structureId
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <summary
                                                className={`main__section-unit-grid-table-summary ${openedUnitSectionFlag &&
                                                    openedUnitSection == el.structureId
                                                        ? 'main__section-unit-grid-table-summary-disabled'
                                                        : ''}`}
                                                // onClick={() => {
                                                //   setOpenedUnitSectionFlag(false);
                                                //   getUnitListingsData(el.StructureId);
                                                //   setTimeout(() => {
                                                //     setOpenedUnitSection(el.StructureId);
                                                //     setOpenedUnitSectionFlag(true);
                                                //   }, 500);
                                                // }}
                                            >
                                                <table className="main__section-unit-grid-table">
                                                    <tbody>
                                                    <tr>
                                                        <td
                                                            className="main__section-unit-grid-table-data head-item head-item-flex"
                                                            onClick={() => {
                                                                setOpenedUnitSectionFlag(false);
                                                                setDefaultMsg(true)
                                                                fetchResources(el.structureId);
                                                                setTimeout(() => {
                                                                    setOpenedUnitSection(el.structureId);
                                                                    setOpenedUnitSectionFlag(true);
                                                                }, 500);
                                                            }}
                                                        >
                                                            {/* <i className={`toggle-collapse-icons`}></i>
                            <i className={`toggle-collapse-icons-toggle`}></i> */}
                                                            <i
                                                                className={`toggle-collapse-icons ${openedUnitSectionFlag &&
                                                                openedUnitSection == el.structureId
                                                                    ? "toggle-collapse-icons-toggle"
                                                                    : ""
                                                                }`}
                                                            ></i>
                                                            <label className="main__section-unit-grid-item-name">
                                                                {
                                                                    el.info.StructureLang[
                                                                        el.info.StructureLang[0].Lang == lang
                                                                            ? 0
                                                                            : 1
                                                                        ].StructureTitle
                                                                }
                                                            </label>
                                                        </td>
                                                        {UserType == "S" && <td
                                                                    className={`main__section-unit-grid-table-data head-submitted`}
                                                                    onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}
                                                                >
                                                                   {/* jklm1  */}
                                                                   {/* {toggleProgress? el.submittedAvg : ""} */}
                                                                </td>}
                                                        <td className="main__section-unit-grid-table-data head-average" onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                        console.log('qwerty-fgfdgd-inside', {openedUnitSection, structureId: el.structureId, openedUnitSectionFlag, defaultMsg})
                                                                    }}>
                                                            {!toggleProgress ||
                                                            mainProgressLevelClass == "" ? (
                                                                <span
                                                                    className={`main__section-unit-grid-details-progress`}
                                                                >
                                    <i className="main__section-unit-grid-details-progress-bar">
                                      -
                                    </i>
                                  </span>
                                                            ) : (
                                                                <div
                                                                    // className={`progressbar progressbar-highest`}
                                                                    className={`progressbar ${mainProgressLevelClass}`}
                                                                    role="progressbar"
                                                                    aria-valuenow={el.avgScore}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{"--value": el.avgScore}}
                                                                ></div>
                                                            )}
                                                            {/* <span
                                  className={`main__section-unit-grid-details-progress`}
                                >
                                  <i className="main__section-unit-grid-details-progress-bar">
                                    -
                                  </i>
                                </span> */}
                                {/* jklm2 */}
                                                        </td>
                                                        {UserType == "T" ? (
                                                            <>
                                                                <td
                                                                    className={`main__section-unit-grid-table-data head-submitted`}
                                                                    onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {/* {toggleProgress? el.submittedAvg : ""} */}
                                                                    {/* jklm3 */}
                                                                </td>
                                                                <td
                                                                    className={`main__section-unit-grid-table-data head-viewed`}
                                                                    onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {/* {toggleProgress? el.readAvg: ""} */}
                                                                    {/* jklm4 */}
                                                                </td>
                                                                <td className="main__section-unit-grid-table-data head-viewable" onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}>
                                                                    {/* jklm5 */}
                                                                    </td>
                                                                <td className="main__section-unit-grid-table-data head-google-classroom" onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}>
                                                                    {/* jklm6 */}
                                                                    </td>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <td className="main__section-unit-grid-table-data head-result" onClick={()=> {
                                                                        if(openedUnitSection == el.structureId ){
                                                                            setDefaultMsg(false)
                                                                        }
                                                                    }}>
                                                            {/* jklm7 */}
                                                            </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </summary>

                                            {openedUnitSection == el.structureId &&
                                            <table className="main__section-unit-grid-table">
                                                <tbody>
                                                {unitItemLoading && openedUnitSection == el.structureId ? (
                                                    <Loading type={`normal`}/>
                                                ) : (
                                                    el.details.length > 0 ?
                                                    el.details.map((obj) => {
                                                        if (checkViewPermission(obj)) {
                                                            return (
                                                                <tr>
                                                                    <td
                                                                        onClick={(e) => {
                                                                            console.log('gatrack', {obj, el})

                                                                            sendGaEvent('SDL_Open_resource_server', {
                                                                                'Resource':obj.ResourceLangs[
                                                                                    obj.ResourceLangs[0].Lang == lang
                                                                                        ? 0
                                                                                        : 1
                                                                                    ].ResourceName,
                                                                                'Resource_ID': obj.IngestionId,
                                                                                'Resource_Type': obj.ResourceType,
                                                                                'Book_Series': props.selectedBookSeriesName,
                                                                                'Book': props.selectedBookName,
                                                                                'Unit': activeBookName,
                                                                                // 'user_Level': obj.MappedLevels[0].LevelName[0].LevelName,
                                                                                'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                                                                'user_School': userSchool,
                                                                                'user_Class': userClass,
                                                                                'user_Role':UserType,
                                                                                'user_Device': window.cordova? 'App': 'Web'
                                                                            })

                                                                            if (!window.cordova) {
                                                                            window.gtag('event', 'SDL - Open resource', {
                                                                                'Resource':obj.ResourceLangs[
                                                                                    obj.ResourceLangs[0].Lang == lang
                                                                                        ? 0
                                                                                        : 1
                                                                                    ].ResourceName,
                                                                                'Resource_ID': obj.IngestionId,
                                                                                'Resource_Type': obj.ResourceType,
                                                                                'Book_Series': props.selectedBookSeriesName,
                                                                                'Book': props.selectedBookName,
                                                                                'Unit': activeBookName,
                                                                                // 'user_Level': obj.MappedLevels[0].LevelName[0].LevelName,
                                                                                'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                                                                'user_Role':UserType
                                                                              });
                                                                            }
                                                                            checkDeviceToOpenOrDownload(
                                                                                obj,
                                                                                "open"
                                                                            );
                                                                        }}
                                                                        className="main__section-unit-grid-table-data head-item head-item-flex"
                                                                    >
                                                                        {/* <span
                                className="main__section-unit-grid-item-image"
                                style={{
                                  backgroundImage: `url(${
                                    obj.Thumbnail != "" && obj.Thumbnail != null
                                      ? window.location.protocol != "http:"
                                        ? "https:" + obj.Thumbnail.FilePath
                                        : "http:" + obj.Thumbnail.FilePath
                                      : ResourceIconMapping(
                                          obj.ResourceType,
                                          "big",
                                          obj.Linkage
                                        )
                                  })`,
                                }}
                              ></span> */}
                                                                        <img
                                                                            alt="logo Image"
                                                                            className="main__section-unit-grid-item-image"
                                                                            src={`${obj.Thumbnail != "" &&
                                                                            obj.Thumbnail != null
                                                                                ? window.location.protocol !=
                                                                                "http:"
                                                                                    ? "https:" +
                                                                                    obj.Thumbnail.FilePath
                                                                                    : "http:" + obj.Thumbnail.FilePath
                                                                                : ResourceIconMapping(
                                                                                    obj.ResourceType,
                                                                                    "big",
                                                                                    obj.Linkage
                                                                                )
                                                                            }`}
                                                                        />
                                                                        <label
                                                                            className="main__section-unit-grid-item-name">
                                                                            {
                                                                                obj.ResourceLangs[
                                                                                    obj.ResourceLangs[0].Lang == lang
                                                                                        ? 0
                                                                                        : 1
                                                                                    ].ResourceName
                                                                            }
                                                                        </label>
                                                                    </td>
                                                                    {UserType == "S" && <td
                                                                    className={`main__section-unit-grid-table-data head-submitted`}
                                                                >
                                                                    {obj.ResourceReadStatus.length>0 && (obj.ResourceType != "PRIZM" && obj.ResourceType != "LEARNOSITY") && (
                                                                    <img
                                                                            alt="logo Image"
                                                                            className="main__section-unit-grid-tick-image"
                                                                            src={tickIcon}
                                                                        />)}
                                                                </td>}
                                                                    {obj.ResourceType == "PRIZM" ||
                                                                    obj.ResourceType == "LEARNOSITY" ? (
                                                                        <td className="main__section-unit-grid-table-data head-average">
                                                                            {/* {!toggleProgress ? (
                                      <span
                                        className={`main__section-unit-grid-details-progress`}
                                      >
                                        <i className="main__section-unit-grid-details-progress-bar">
                                          -
                                        </i>
                                      </span>
                                    ) : (
                                      <div
                                        className={`progressbar progressbar-highest`}
                                        role="progressbar"
                                        aria-valuenow={18}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ "--value": 18 }}
                                      ></div>
                                    )} */}
                                                                            {!toggleProgress ? (
                                                                                <span
                                                                                    className={`main__section-unit-grid-details-progress`}
                                                                                >
                                            <i className="main__section-unit-grid-details-progress-bar">
                                              -
                                            </i>
                                          </span>
                                                                            ) : (
                                                                                showScore(obj)
                                                                            )}
                                                                        </td>
                                                                    ) : (
                                                                        <td className="main__section-unit-grid-table-data head-average"></td>
                                                                    )}
                                                                    {UserType == "T" ? (
                                                                        <>
                                                                            {obj.ResourceType == "PRIZM" ||
                                                                            obj.ResourceType == "LEARNOSITY" ? (
                                                                                <td
                                                                                    className={`main__section-unit-grid-table-data head-submitted ${toggleProgress
                                                                                        ? "main__section-unit-grid-table-data-label"
                                                                                        : ""
                                                                                    }`}
                                                                                >
                                                                                    {!toggleProgress
                                                                                        ? "-"
                                                                                        : obj
                                                                                            .SelfLearnLastAttemptHistoryByStudyGroup
                                                                                            .length > 0
                                                                                            ? getSubmit(obj)
                                                                                            : 0}
                                                                                </td>
                                                                            ) : (
                                                                                <td
                                                                                    className={`main__section-unit-grid-table-data head-viewed ${toggleProgress
                                                                                        ? "main__section-unit-grid-table-data-label"
                                                                                        : ""
                                                                                    }`}
                                                                                ></td>
                                                                            )}
                                                                            {obj.ResourceType == "PRIZM" ||
                                                                            obj.ResourceType == "LEARNOSITY" ? (
                                                                                <td
                                                                                    className={`main__section-unit-grid-table-data head-viewed ${toggleProgress
                                                                                        ? "main__section-unit-grid-table-data-label"
                                                                                        : ""
                                                                                    }`}
                                                                                ></td>
                                                                            ) : (
                                                                                <td
                                                                                    className={`main__section-unit-grid-table-data head-viewed ${toggleProgress
                                                                                        ? "main__section-unit-grid-table-data-label"
                                                                                        : ""
                                                                                    }`}
                                                                                >
                                                                                    {!toggleProgress
                                                                                        ? "-"
                                                                                        : obj.ResourceReadStatus
                                                                                            ? <label className={`${obj.ResourceReadStatus.length>0 ? "main__section-unit-grid-table-viewed-cnt" : ""}`}
                                                                                            onClick={()=> showAttempHistory(obj)}>{obj.ResourceReadStatus.length}</label>
                                                                                            : "-"}
                                                                                </td>
                                                                            )}
                                                                            <td className="main__section-unit-grid-table-data head-viewable">
                                                                                {checkViewandRedo(
                                                                                    obj,
                                                                                    "view",
                                                                                    openedUnitSection
                                                                                )}
                                                                            </td>
                                                                            <td className="main__section-unit-grid-table-data head-google-classroom">
                                                                                <div
                                                                                    id={"widget-div" + obj.ResourceId}
                                                                                ></div>
                                                                                <img
                                                                                            src={gClassroomLogo32}
                                                                                            onClick={() => {
                                                                                                if (!window.cordova) {
                                                                                                    window.gtag('event', 'SDL - Share to google classroom', {
                                                                                                        'Resource':obj.ResourceLangs[
                                                                                                            obj.ResourceLangs[0].Lang == lang
                                                                                                                ? 0
                                                                                                                : 1
                                                                                                            ].ResourceName,
                                                                                                        'Resource_ID': obj.IngestionId,
                                                                                                        'Resource_Type': obj.ResourceType,
                                                                                                        'Book_Series': props.selectedBookSeriesName,
                                                                                                        'Book': props.selectedBookName,
                                                                                                        'Unit': el.info.StructureLang[
                                                                                                            el.info.StructureLang[0].Lang == lang
                                                                                                                ? 0
                                                                                                                : 1
                                                                                                            ].StructureTitle,
                                                                                                        // 'user_Level': obj.MappedLevels[0].LevelName[0].LevelName,
                                                                                                        'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                                                                                        'user_Role': UserType
                                                                                                        })
                                                                                                    } else {
                                                                                                        // sendGaEvent('SDL - Share to google classroom', {
                                                                                                        //     'Resource':obj.ResourceLangs[
                                                                                                        //         obj.ResourceLangs[0].Lang == lang
                                                                                                        //             ? 0
                                                                                                        //             : 1
                                                                                                        //         ].ResourceName,
                                                                                                        //     'Resource_ID': obj.ResourceId,
                                                                                                        //     'Resource_Type': obj.ResourceType,
                                                                                                        //     'Book_Series': props.selectedBookSeriesName,
                                                                                                        //     'Book': props.selectedBookName,
                                                                                                        //     'Unit': el.info.StructureLang[
                                                                                                        //         el.info.StructureLang[0].Lang == lang
                                                                                                        //             ? 0
                                                                                                        //             : 1
                                                                                                        //         ].StructureTitle,
                                                                                                        //     // 'user_Level': obj.MappedLevels[0].LevelName[0].LevelName,
                                                                                                        //     'user_Level': studyGroupData.StudyGroupName[studyGroupData.StudyGroupName[0].Lang == lang ? 0 : 1].StudyGroupName.split('')[0],
                                                                                                        //     'user_Role': UserType
                                                                                                        // })
                                                                                                    }
                                                                                                
                                                                                                
                                                                                                onClickGoogleClassroom("widget-div" + obj.ResourceId,
                                                                                            obj.IngestionId,
                                                                                            el.structureId,
                                                                                            el.info.StructureLang[
                                                                                                el.info.StructureLang[0].Lang == lang
                                                                                                    ? 0
                                                                                                    : 1
                                                                                                ].StructureTitle)}}
                                                                                        />

                                                                                {/* <button className="btns buttons google-btn" onClick={(e) => {
                                            renderWidget();
                                           }}>
                                             <div id="widget-div"></div>
                                            <i className="google-class-room"></i>
                                          </button> */}
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td className="main__section-unit-grid-table-data head-result">
                                                                        {/* <button className="btns buttons results-btn"> */}
                                                                        {(obj.ResourceType == "PRIZM" ||
                                                                                obj.ResourceType == "LEARNOSITY") &&
                                                                            UserType == "T" &&
                                                                            (obj.SelfLearnLastAttemptHistoryByStudyGroup ? (
                                                                                obj
                                                                                    .SelfLearnLastAttemptHistoryByStudyGroup
                                                                                    .length > 0 && (
                                                                                    <button
                                                                                        className="btns buttons results-btn"
                                                                                        onClick={(e) => {
                                                                                            // props.history.push(
                                                                                            //   "/a/studygroup/" +
                                                                                            //     props.match.params.id +
                                                                                            //     "/assessment/" +
                                                                                            //     "prizm" +
                                                                                            //     "/" +
                                                                                            //     obj.ResourceId +
                                                                                            //     "/result"
                                                                                            // );
                                                                                            // props.history.push({
                                                                                            //   pathname:
                                                                                            //     "/a/studygroup/" +
                                                                                            //     props.match.params.id +
                                                                                            //     "/assessment/" +
                                                                                            //     "prizm" +
                                                                                            //     "/" +
                                                                                            //     obj.ResourceId +
                                                                                            //     "/result",
                                                                                            //   state: {
                                                                                            //     selectedBookId:
                                                                                            //       selectedBookId,
                                                                                            //   },
                                                                                            // });
                                                                                            if (
                                                                                                obj.ResourceType == "PRIZM"
                                                                                            ) {
                                                                                                doOpenPrizmResult(e, obj);
                                                                                            } else {
                                                                                                props.history.push({
                                                                                                    pathname:
                                                                                                        "/a/studygroup/" +
                                                                                                        props.match.params.id +
                                                                                                        "/assessment/" +
                                                                                                        "qs&" +
                                                                                                        selectedBookId +
                                                                                                        "/" +
                                                                                                        obj.ResourceId +
                                                                                                        "/Result",
                                                                                                    state: {
                                                                                                        backURL: "SDL",
                                                                                                    },
                                                                                                });
                                                                                                dispatch({
                                                                                                    type: "SET_SELECTED_TYPE",
                                                                                                    selectOptions: {
                                                                                                        selectedStudyGroupId:
                                                                                                        tabClassId,
                                                                                                        selectedStudyGroupName:
                                                                                                        selectedClass,
                                                                                                        setselectedResAssignId:
                                                                                                            "",
                                                                                                        selectecResourceTab:
                                                                                                        activeBook,
                                                                                                        selectedResourceName:
                                                                                                        activeBookName,
                                                                                                        selectedToggle:
                                                                                                        toggleProgress,
                                                                                                        seletctedTabSDL:
                                                                                                            "Resource",
                                                                                                        selectedReportType: "",
                                                                                                        selectedAssessType: "",
                                                                                                        firstLevel: "", //activeFetchId,
                                                                                                        secondLevel: "", //bredCrumRes[1],
                                                                                                        thirdLevel: "", //bredCrumRes[2],
                                                                                                        backpathRes:"SDL"
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {t("Result")}
                                                                                    </button>
                                                                                )
                                                                            ) : (
                                                                                <div></div>
                                                                            ))}
                                                                        {(obj.ResourceType == "PRIZM" ||
                                                                                obj.ResourceType == "LEARNOSITY") &&
                                                                            UserType == "S" &&
                                                                            (obj.SelfLearnAttemptHistory ? (
                                                                                obj.SelfLearnAttemptHistory.length >
                                                                                0 && (
                                                                                    <button
                                                                                        className="btns buttons results-btn"
                                                                                        onClick={() => {
                                                                                            // props.history.push(
                                                                                            //   "/a/studygroup/" +
                                                                                            //     props.match.params.id +
                                                                                            //     "/assessment/" +
                                                                                            //     "prizm" +
                                                                                            //     "/" +
                                                                                            //     obj.ResourceId +
                                                                                            //     "/result"
                                                                                            // );

                                                                                            var attemptURL = getPreviewURL(
                                                                                                obj.SelfLearnAttemptHistory
                                                                                            );
                                                                                            if (
                                                                                                obj.ResourceType == "PRIZM"
                                                                                            ) {
                                                                                                if (isIOS) {

                                                                                                    iosOpenPrizmSubmission(attemptURL, obj.ResourceLangs[0].ResourceName);
                                                                                                    return;
                                                                                                }
                                                                                                props.history.push({
                                                                                                    pathname:
                                                                                                        "/a/studygroup/" +
                                                                                                        props.match.params.id +
                                                                                                        "/assessment/" +
                                                                                                        "prizm&" +
                                                                                                        selectedBookId +
                                                                                                        "/" +
                                                                                                        obj.ResourceId +
                                                                                                        "/submission",
                                                                                                    state: {
                                                                                                        backURL: "SDL",
                                                                                                        previewURL: attemptURL,
                                                                                                    },
                                                                                                });
                                                                                                dispatch({
                                                                                                    type: "SET_SELECTED_TYPE",
                                                                                                    selectOptions: {
                                                                                                        selectedStudyGroupId:
                                                                                                        tabClassId,
                                                                                                        selectedStudyGroupName:
                                                                                                        selectedClass,
                                                                                                        setselectedResAssignId:
                                                                                                            "",
                                                                                                        selectedReportType: "",
                                                                                                        selectedAssessType: "",
                                                                                                        selectedToggle:
                                                                                                        toggleProgress,
                                                                                                        selectecResourceTab:
                                                                                                        activeBook,
                                                                                                        selectedResourceName:
                                                                                                        activeBookName,
                                                                                                        firstLevel: "", //activeFetchId,
                                                                                                        secondLevel: "", //bredCrumRes[1],
                                                                                                        thirdLevel: "", //bredCrumRes[2],
                                                                                                    },
                                                                                                });
                                                                                            } else {
                                                                                                props.history.push({
                                                                                                    pathname:
                                                                                                        "/a/studygroup/" +
                                                                                                        props.match.params.id +
                                                                                                        "/assessment/" +
                                                                                                        "qs&" +
                                                                                                        selectedBookId +
                                                                                                        "/" +
                                                                                                        obj.ResourceId +
                                                                                                        "/Result",
                                                                                                    state: {
                                                                                                        backURL: "SDL",
                                                                                                        group_id: tabClassId,
                                                                                                    },
                                                                                                });
                                                                                                dispatch({
                                                                                                    type: "SET_SELECTED_TYPE",
                                                                                                    selectOptions: {
                                                                                                        selectedStudyGroupId:
                                                                                                        tabClassId,
                                                                                                        selectedStudyGroupName:
                                                                                                        selectedClass,
                                                                                                        setselectedResAssignId:
                                                                                                            "",
                                                                                                        selectedReportType: "",
                                                                                                        selectedAssessType: "",
                                                                                                        selectedToggle:
                                                                                                        toggleProgress,
                                                                                                        selectecResourceTab:
                                                                                                        activeBook,
                                                                                                        selectedResourceName:
                                                                                                        activeBookName,
                                                                                                        firstLevel: "", //activeFetchId,
                                                                                                        secondLevel: "", //bredCrumRes[1],
                                                                                                        thirdLevel: "", //bredCrumRes[2],
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {t("View")}
                                                                                    </button>
                                                                                )
                                                                            ) : (
                                                                                <div></div>
                                                                            ))}
                                                                        {/* </button> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    }) : defaultMsg && openedUnitSection == el.structureId && <p className="main__section-no-data">{t("There are no resources yet")}</p>
                                                )}
                                                </tbody>
                                            </table>
                                            }
                                        </details>
                                    );
                                }): <p class="main__section-unit-no-data">{t("There are no resources yet")}</p>}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default UnitPage;